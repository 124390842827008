import React, { useState } from "react";
import StateList from "../state-list.json";
import { Modal } from "@mui/material";
import "./address-modal.scss";
import { getSecondAddressList } from "../../service/addressSearch.js";
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import { FormattedMessage } from "react-intl";

function AddressModal({
  showAddressModal,
  setShowAddressModal,
  setUspsAddressInput,
  setPostOffices,
  setLoading,
  enrollmentCode,
}) {
  const [streetAddress, setStreetAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const gaEventTracker = useAnalyticsEventTracker('Address Modal');

  const onModalAddressSearchBtnClick = async () => {
    try {
      setUspsAddressInput({
        uspsStreetAddress: `${streetAddress} ${apartment}` || "",
        uspsCity: city || "",
        uspsState: state || "",
        uspsZipCode: zipcode || ""
      });

      const payload = {
        sponsorID: 17,
        uniqueID: "AA97B177-9383-4952",
        streetAddress: `${streetAddress} ${apartment}` || "",
        city: city || "",
        state: state || "",
        zipCode: zipcode || "",
        enrollmentCode: enrollmentCode
      };
      setLoading(true);
      const response = await getSecondAddressList(JSON.stringify(payload));

      if (response) {
        setPostOffices(response.data.postOffices || []);
      }
      setLoading(false);
      setStreetAddress("");
      setApartment("");
      setCity("");
      setState("");
      setZipcode("");
      setShowAddressModal(false);
    } catch (err) {
      console.log("Error =>", err);
      setShowAddressModal(false);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={showAddressModal}
      onClose={() => { 
        gaEventTracker("Close Address Modal");
        return setShowAddressModal(false); 
      }}
      className="address-modal"
    >
      <div className="address-modal-container">
        <div className="address-modal-header">
          <h4><FormattedMessage id="address_modal_header" /></h4>
          <p>
          <FormattedMessage id="address_modal_subheader" />
          </p>
        </div>
        <div className="address-form">
          <div className="modal-input-field">
            <label htmlFor="street-address"><FormattedMessage id="input_street_address_1" /></label>
            <input
              id="newAdress-streetAddress"
              name="street-address"
              data-test-id="newAdress-streetAddress"
              value={streetAddress}
              onChange={e => setStreetAddress(e.target.value)}
            />
          </div>
          <div className="modal-input-field">
            <label htmlFor="apartment"><FormattedMessage id="input_street_address_2" /></label>
            <input
              id="newAdress-apartment"
              name="apartment"
              data-test-id="newAdress-apartment"
              value={apartment}
              onChange={e => setApartment(e.target.value)}
            />
          </div>
          <div className="inline-modal-fields">
            <div className="modal-input-field city-field">
              <label htmlFor="city"><FormattedMessage id="input_city_name" /></label>
              <input
                id="newAdress-city"
                name="city"
                value={city}
                data-test-id="newAdress-city"
                onChange={e => setCity(e.target.value)}
              />
            </div>
            <div className="modal-input-field state-field">
              <label htmlFor="state"><FormattedMessage id="input_state_name" /></label>
              <select
                name="state"
                id="newAdress-state"
                className="state-dropdown"
                onChange={e => {
                  setState(e.target.value);
                }}
                value={state}
                data-test-id="newAdress-state"
              >
                <option key=""><FormattedMessage id="Select_State_DropdownText" /></option>
                {StateList.map(state => {
                  return (
                    <option
                      id={state.abbreviation}
                      key={state.abbreviation}
                      data-test-id={state.abbreviation}
                    >
                      {state.abbreviation}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="modal-input-field zip-field">
            <label htmlFor="zipcode"><FormattedMessage id="address_modal_zip" /></label>
            <input
              id="newAdress-zipCode"
              name="zipcode"
              value={zipcode}
              data-test-id="newAdress-zipCode"
              onChange={e => setZipcode(e.target.value)}
            />
          </div>
          <div className="modal-search-btn-container">
            <button
              className="search-btn"
              onClick={() => {
                gaEventTracker("Search Button");
                return onModalAddressSearchBtnClick();
              }}
            >
              <FormattedMessage id="address_modal_search_button" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddressModal;
