import React from "react";
import "./Stepper.scss";
import { getUrlParams } from "../../utils/utils";
import { FormattedMessage } from "react-intl";

function StepActiveStatus(props) {
    const { activeStep } = props;

    const params = getUrlParams();

    return (
      <span className={`active-status active-status-${activeStep.id} active-status-lang-${params["lang_cd"]}`}>
        <span className="active-badge">{activeStep.id}</span>
        <span className="status-msg-1">
          <FormattedMessage id="of_text" />
          <span className="total-step-count">2</span>
        </span>
      </span>
    );
  }

  export default StepActiveStatus;