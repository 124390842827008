import React, { useEffect } from "react";
import {
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  TableSortLabel
} from "@mui/material";
import { REPORT_COLUMNS } from "../../constants";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import StatusModal from "../statusModal/StatusModal";
import EmptyResult from "./EmptyResult";
import { copyToClipboard } from "../../utils/utils";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

const ReportTable = props => {
  const gaEventTracker = useAnalyticsEventTracker("Report Table");

  const { data, onSetSnackbarMessage, searchString, loading } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("First Name");
  const [showStatusModal, setShowStatusModal] = React.useState(false);

  useEffect(() => {
    const filteredRows = data.filter(element => {
      return Object.values(element).find(
        val => val && val.toLowerCase().includes(searchString.toLowerCase())
      );
    });

    setRows(filteredRows);
  }, [searchString, data]);

  const handleChangePage = (_event, newPage) => {
    gaEventTracker("Pagination Clicked; Page = " + newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    gaEventTracker(
      "Updated Rows per Page; Rows = " + parseInt(event.target.value, 10)
    );
    setPage(0);
  };

  const createSortHandler = property => event => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  return (
    <div className="report-table">
      {rows.length == 0 && !loading && searchString ? (
        <EmptyResult />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead className="reports-table-header">
                <TableRow>
                  {REPORT_COLUMNS.map(column => {
                    return (
                      <TableCell
                        className="report-table-cell"
                        key={column.key}
                        sortDirection={orderBy === column.key ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === column.key}
                          direction={orderBy === column.key ? order : "asc"}
                          onClick={createSortHandler(column.key)}
                        >
                          {column.label}
                          {orderBy === column.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="fw-400">{row.firstName}</TableCell>
                      <TableCell className="fw-400">{row.lastName}</TableCell>
                      <TableCell className="fw-400">
                        {row.legalAddress}
                      </TableCell>
                      <TableCell className="fw-400">
                        {row.postOfficeAddress}
                      </TableCell>
                      <TableCell className="fw-400">
                        {row.expirationDate}
                      </TableCell>
                      <TableCell
                        className="status"
                        onClick={() => {
                          gaEventTracker(`Show Status Modal`);
                          setShowStatusModal(true);
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell className="fw-400">{row.proofDate}</TableCell>
                      <TableCell
                        className="cursor-pointer text-blue"
                        onClick={() => {
                          gaEventTracker(
                            `Email Copied to clipboard; Email: ${row.actualEmail}`
                          );
                          copyToClipboard(row.actualEmail);
                          onSetSnackbarMessage(
                            `${row.actualEmail} copied to the clipboard`
                          );
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        className="cursor-pointer text-blue"
                        onClick={() => {
                          gaEventTracker(
                            `Enrollment code ${row.actualClaim} copied to clipboard`
                          );
                          copyToClipboard(row.actualClaim);
                          onSetSnackbarMessage(
                            `Enrollment code ${row.actualClaim} copied to clipboard`
                          );
                        }}
                      >
                        {row.claim}
                      </TableCell>
                      <TableCell className="text-blue">{row.id1}</TableCell>
                      <TableCell className="text-blue">{row.id2}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 25, 100]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {showStatusModal && (
            <StatusModal
              showStatusModal={showStatusModal}
              setShowStatusModal={setShowStatusModal}
            />
          )}
        </>
      )}
    </div>
  );
};
export default ReportTable;
