import React, { useState } from "react";
import "./sign-in.scss";
import FederalFooter from "../federal-footer/FederalFooter";
import { Link } from "react-router-dom";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

function SignIn() {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");

  const gaEventTracker = useAnalyticsEventTracker('Sign In');

  return (
    <div className="sign-in">
      <div className="wrapper">
        <div className="heading">Sign in</div>
        <div className="sub-heading">Access your account.</div>
        <div className="input-field">
          <label htmlFor="email-address">Email address</label>
          <input
            id="email-address"
            name="email-address"
            data-test-id="email-address"
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
          />
        </div>
        <div className="input-field">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            data-test-id="password"
            value={password}
            type={passwordInputType}
            onChange={e => setPassword(e.target.value)}
          />
          <div
            className="link"
            onClick={() => {
              gaEventTracker('Show passsword');

              if (passwordInputType === "text") {
                setPasswordInputType("password");
              } else {
                setPasswordInputType("text");
              }
            }}
          >
            Show password
          </div>
        </div>
        <div className="modal-sign-in-container">
          <button
            id="sign-in"
            className="sign-in-btn"
            data-test-id="sign-in"
            onClick={() => gaEventTracker('Signin Button')}
          >
            Sign In
          </button>
          <div className="link"><Link onClick={() => gaEventTracker('forgot passsword')} to="/forgotpassword">Forgot Password?</Link></div>
        </div>
      </div>
      <div className="footer">
        <FederalFooter />
      </div>
    </div>
  );
}

export default SignIn;
