import { useState } from "react";
import { FormattedMessage } from "react-intl";
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker';

const Header = () => {
  const gaEventTracker = useAnalyticsEventTracker('Header');
  const [banner, setBanner] = useState("none");
  const envData = ['dev', 'test', 'stage'];
  const env = process.env.REACT_APP_ENVIRONMENT;

  return <header className="usa-header usa-header--basic">

    <a className="usa-skipnav" href="#main-content">Skip to main content</a>
    { envData.includes(env) ? <section role="banner" aria-label="Test website">
      <div className="padding-1 bg-secondary-darker text-white line-height-1 text-center">
        TEST SITE - Do not use real personal information (demo purposes only) - TEST SITE
      </div>
    </section> : <></> }
    <header className="usa-banner__header">
      <div className="usa-banner__inner">
        <div className="grid-col-auto">
          <img
            className="usa-banner__header-flag"
            src="static/img/us_flag_small.png"
            alt="U.S. flag"
          />
        </div>
        <div className="grid-col-fill tablet:grid-col-auto">
          <p className="usa-banner__header-text">
            <FormattedMessage id="banner_header_text" />
          </p>
          <p className="usa-banner__header-action" aria-hidden="true">
            <FormattedMessage id="banner_button_text" />
          </p>
        </div>
        {/* onClick={() => gaEventTracker('Clicked on Home Logo')} */}
        <button onClick={() => { setBanner(banner === "block" ? "none" : "block"); gaEventTracker("Clicked on Heres how you know"); }} className="usa-banner__button">
          <span className="usa-banner__button-text"><FormattedMessage id="banner_button_text" /></span>
        </button>
      </div>
    </header>
    <div className="usa-banner__content usa-accordion__content" style={{ display: banner }} id="gov-banner">
      <div className="grid-row grid-gap-lg">
        <div className  ="usa-banner__guidance tablet:grid-col-6">
          <img
            className="usa-banner__icon usa-media-block__img"
            src="static/img/icon-dot-gov.svg"
            role="img"
            alt=""
            aria-hidden="true"
          />
          <div className="usa-media-block__body">
            <p>
              <strong><FormattedMessage id="header_media_block" /></strong>
              <br />
              <FormattedMessage id="header_media_block_two" /> <strong>.gov</strong> <FormattedMessage id="header_media_block_three" />
            </p>
          </div>
        </div>
        <div className="usa-banner__guidance tablet:grid-col-6">
          <img
            className="usa-banner__icon usa-media-block__img"
            src="static/img/icon-https.svg"
            role="img"
            alt=""
            aria-hidden="true"
          />
          <div className="usa-media-block__body">
            <p>
              <strong><FormattedMessage id="header_media_block_body" /> </strong>
              <br />
              <FormattedMessage id="header_media_block_two_inner" /> <strong><FormattedMessage id="header_media_block_one" /></strong>(
              <span className="icon-lock"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="64"
                viewBox="0 0 52 64"
                className="usa-banner__lock-image"
                role="img"
                aria-labelledby="banner-lock-title banner-lock-description"
                focusable="false"
              >
                  <title id="banner-lock-title"><FormattedMessage id="banner_lock_title" /></title>
                  <desc id="banner-lock-description">
                  <FormattedMessage id="banner_lock_description_main" />
                  </desc>
                  <path
                    fill="#000000"
                    fillRule="evenodd"
                    d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                  /></svg
                ></span>
              )
              <FormattedMessage id="banner_lock_description_one" /> <strong>https://</strong><FormattedMessage id="banner_lock_description_two" />

            </p>
          </div>
        </div>
      </div>
    </div>
    <section role="banner" className="usa-banner dol-banner bg-primary-darker">
      <header className="usa-banner__header">
        <div className="usa-banner__inner">
          <a href="https://www.dol.gov/" className="text-no-underline" style={{ display: 'flex' }} >
            <div className="grid-col-auto">
              <img className="float-left height-205 margin-right-05" src="static/img/Agency_DOL_Logo_dark.svg"
                alt="United States Department of Labor" />
            </div>
            <div className="grid-col-fill tablet:grid-col-auto padding-top-05">
              <p className="usa-banner__header-text usdol-title">
                <strong className="text-base-lightest TEXT-UPPER"><FormattedMessage id="header_usdol_title" /></strong>
              </p>
            </div>
          </a>
        </div>
      </header>
    </section>
  </header>
}

export default (Header);
