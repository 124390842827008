import './address-list.scss';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { useEffect, useState } from 'react';

function AddressList(props) {
  const { postOffices, heading, subHeading } = props;
  const [key, setKey] = useState(0);
  
  useEffect(() => {
    setKey(Math.random());
  }, [postOffices]);

  return (
    <div className="address-list" key={key}>     
      <p className="sub-heading">{subHeading ? subHeading : ""}</p>
      {postOffices?.map(postOffice => {
        const { streetAddress, city, state, zip5, hours, phone, distance } = postOffice;
        return (<div key={zip5} className="address">
            <LocationIcon aria-label="Location List"/>
            <span>{streetAddress}, {city}, {state} {zip5}</span>
        </div>)
      })}
    </div>
  );
}

export default AddressList;
