import React, { useState } from "react";
import "./reset-password.scss";
import FederalFooter from "../federal-footer/FederalFooter";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

function ResetPassword() {
  const gaEventTracker = useAnalyticsEventTracker('Reset Password');
  const [password, setPassword] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [reTypePassword, setReTypePassword] = useState("");
  const [reTypePasswordType, setReTypePasswordType] = useState("password");
  return (
    <div className="reset-password">
      <div className="wrapper">
        <div className="heading">Reset Password</div>
        <div className="sub-heading">Create a new password</div>
        <div className="input-field">
          <label htmlFor="password">Enter Password</label>
          <input
            id="password"
            name="password"
            data-test-id="password"
            value={password}
            type={passwordInputType}
            onChange={e => setPassword(e.target.value)}
          />
          <div
            className="link"
            onClick={() => {
              if (passwordInputType === "text") {
                setPasswordInputType("password");
              } else {
                setPasswordInputType("text");
              }
            }}
          >
            Show password
          </div>
        </div>
        <div className="input-field">
          <label htmlFor="email-address">Re-type passsword</label>
          <input
            id="retype-password"
            name="retype-password"
            data-test-id="retype-password"
            value={reTypePassword}
            type={reTypePasswordType}
            onChange={e => setReTypePassword(e.target.value)}
            onClick={() => {
              if (reTypePasswordType === "text") {
                setReTypePasswordType("password");
              } else {
                setReTypePasswordType("text");
              }
            }}
          />
          <div
            className="link"
            onClick={() => {
              if (passwordInputType === "text") {
                setReTypePasswordType("password");
              } else {
                setReTypePasswordType("text");
              }
            }}
          >
            Show password
          </div>
        </div>
        <div className="modal-sign-in-container">
          <button
            id="reset-password"
            className="reset-password-btn"
            data-test-id="reset-password"
            onClick={() => gaEventTracker('Save Password Button')}
          >
            Save Password
          </button>
        </div>
      </div>
      <div className="footer">
        <FederalFooter />
      </div>
    </div>
  );
}

export default ResetPassword;
