import states from '../i18n/states.js';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_ENVIRONMENT = process.env?.REACT_APP_ENVIRONMENT;

export const copyToClipboard = text => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const getCurrentDate = () => {
  const d = new Date();
  const dformat =
    [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
    " " +
    [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
  return dformat;
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  return {...params, swa_xid: params?.swa_xid && params?.swa_xid.replaceAll(" ","+")};
}

export const getUICPRedirect = () => {
  const DEV_ENV = "https://dev1-unemployment.dol.gov";
  const TEST_ENV = "https://test1-unemployment.dol.gov";
  const STAGE_ENV = "https://stage1-unemployment.dol.gov";
  const PROD_ENV = "https://unemployment.dol.gov";

  let host = "";

  if(BASE_URL?.includes('dev-')) {
    host = DEV_ENV;
  } else if (BASE_URL?.includes('test-')) {
    host = TEST_ENV;
  } else if (BASE_URL?.includes('stage-')) {
    host = STAGE_ENV;
  } else {
    host = PROD_ENV;
  }

  return host;
}

export const validateUatEnv = () => {
  let result = REACT_APP_ENVIRONMENT === "uat" ? true : false;
  return result;
}

export const validateUrl = (swa, id) => {
  return (states[swa]?.state_regex)?.test(id);
};