import React from "react";
import { FormattedMessage } from "react-intl";

export const EmailTemplate = (params) => {
  return(
    <a href={params.href}>
        <span className="state-email-link">
          <FormattedMessage id={`state_email`} />
        </span>
      </a>
  )
};
