import React, { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import "./reports.scss";
import ReportTable from "./ReportTable";
import TechnicalSupport from "./TechnicalSupport";
import { getIPPReportTableData } from "../../service/reportService";
import { Snackbar } from "@mui/material";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import { FormattedMessage } from "react-intl";

function Reports(props) {
  const { updateHeadingText ,setLoading, loading} = props;
  const gaEventTracker = useAnalyticsEventTracker("Reports");

  const [searchString, setSearchString] = useState("");
  const [showTechnicalSupport, setShowTechnicalSupport] = useState(false);
  const [ippReportData, setIppReportData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  
  const onToggleChange = () => {
    !showTechnicalSupport
      ? updateHeadingText(
          <h1>
            <FormattedMessage id="updatedTechnicalHeader_text" />
          </h1>
        )
      : updateHeadingText(
          <h1>
            <FormattedMessage id="reports_component_heading" />
          </h1>
        );

    setShowTechnicalSupport(!showTechnicalSupport);
    localStorage.setItem("showTechnicalSupport", !showTechnicalSupport);
    window.dispatchEvent(new Event("storage"));
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      try{
        const reportData = await getIPPReportTableData(showTechnicalSupport);
        if(reportData.length){
          setIppReportData(reportData);
        }
      }catch(err){
        console.log("getIPPReportTableData api failed due to: ", err)
      }finally{
        setLoading(false)
      }
    };
    init();
  }, [showTechnicalSupport]);

  const onSearchStringChange = searchString => {
    setSearchString(searchString);
  };

  const onSetSnackbarMessage = message => {
    setSnackbarMessage(message);
    setTimeout(() => {
      setSnackbarMessage("");
    }, 3000);
  };

  return (
    <div className="reports-table">
      <TableHeader
        onToggleChange={onToggleChange}
        onSearchStringChange={onSearchStringChange}
        showTechnicalSupport={showTechnicalSupport}
        data={ippReportData}
      />

      {showTechnicalSupport ? (
        <TechnicalSupport searchString={searchString} />
      ) : (
        <ReportTable
          data={ippReportData}
          searchString={searchString}
          onSetSnackbarMessage={onSetSnackbarMessage}
          loading={loading}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
        key={"topcenter"}
      />
    </div>
  );
}

export default Reports;
