import './federal-footer.scss';
const FederalFooter = function() {
  return (
    <div className="federal-footer">
      <div className="no-account">
        Don't have an account <span className="link">create an account now</span>
      </div>
      <div>
          <div className="heading">Are you a federal employee</div>
          <div className="description">If you are a federal employee or (other secondary user), please use secondary Single Sign On (SSO)]</div>
          <div className="launch-sso-btn">
              Launch secondary SSO
          </div>
      </div>
    </div>
  );
};

export default FederalFooter;