import React, { useEffect } from "react";
import {
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  TableSortLabel
} from "@mui/material";
import {
  TECHNICAL_REPORT_COLUMNS,
  TECHNICAL_REPORTS_ROWS
} from "../../constants";
import { ReactComponent as JsonIcon } from "../../icons/jsonIcon.svg";
import "./technical-support.scss";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

const TechnicalSupport = props => {
  const gaEventTracker = useAnalyticsEventTracker("Technical Support");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState(TECHNICAL_REPORTS_ROWS);
  const { searchString } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Enroll Code");

  useEffect(() => {
    const data = TECHNICAL_REPORTS_ROWS.filter(element => {
      return Object.values(element).find(val =>
        val.toLowerCase().includes(searchString.toLowerCase())
      );
    });
    setRows(data);
  }, [searchString]);

  const handleChangePage = (_event, newPage) => {
    gaEventTracker("Pagination Clicked; Page = " + newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    gaEventTracker(
      "Updated Rows per Page; Rows = " + parseInt(event.target.value, 10)
    );
    setPage(0);
  };

  const createSortHandler = property => event => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  return (
    <div className="technical-support-table">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead className="technical-reports-table-header">
            <TableRow>
              {TECHNICAL_REPORT_COLUMNS.map(column => {
                return (
                  <TableCell
                    className="technical-report-table-cell"
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="fw-400">{row.enrollCode}</TableCell>
                  <TableCell className="json-icon" text-align="center">
                    {<JsonIcon />}
                  </TableCell>
                  <TableCell className="json-icon" text-align="center">
                    {<JsonIcon />}
                  </TableCell>
                  <TableCell className="fw-400">{row.timeSubmitted}</TableCell>
                  <TableCell className="fw-400">{row.timeRecived}</TableCell>
                  <TableCell className="fw-400">{row.timeCreated}</TableCell>
                  <TableCell className="fw-400">{row.timeUpdated}</TableCell>
                  <TableCell className="fw-400">{row.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 100]}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
export default TechnicalSupport;
