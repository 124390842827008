import React from "react";
import "./usps-description.scss";
import { FormattedMessage } from "react-intl";
import { Form } from "react-router-dom";
import { Email } from "./templates/Email"
import { richText } from "../../i18n/states"

export const USPSDescription = (stateParams) => {
  return (
    <section className="usps-description-container">
      <section>
        <h3 className="documents-info-label">
          <FormattedMessage id="required_Identity_documents" />
        </h3>
        <div className="documents-info-conainer">
          <div className="documents-info">
            <h3 className="required-document-headings">
              <FormattedMessage id="acceptable_forms_of_ID" />
            </h3>
            <ul className="list-style">
              <li>
                <FormattedMessage id="State_drivers_license" />
              </li>
              <li>
                <FormattedMessage id="state_id_card" />
              </li>
              <li>
                <FormattedMessage id="uniformed_id_cards" />
              </li>
              <li>
                {" "}
                <FormattedMessage id="secondary_id" />
              </li>
            </ul>
          </div>
        </div>
        <div className="documents-info-conainer">
          <div className="documents-info">
            <h3 className="required-document-headings">
              <FormattedMessage id="required_docs_heading" />
            </h3>{" "}
            <FormattedMessage id="required_docs_para" />
            <ul className="list-style">
              <li>
                <FormattedMessage id="reqired_docs_list_lease" />
              </li>
              <li>
                <FormattedMessage id="reqired_docs_list_voter" />
              </li>
              <li>
                <FormattedMessage id="reqired_docs_list_vehical" />
              </li>
              <li>
                <FormattedMessage id="reqired_docs_list_home" />
              </li>
            </ul>
          </div>
        </div>
        <section className="postoffice-info-conainer">
          <div className="required-document-headings">
            <FormattedMessage id="postOffice_text" values={{sup: data => <sup>{data}</sup>, u: data => <u>{data}</u>}}/>{" "}
            <span>
              <FormattedMessage id="postOffice_span" />
            </span>
          </div>
          <ul className="list-style">
            <li>
              <FormattedMessage id="list_social_security" />
            </li>
            <li>
              <FormattedMessage id="list_credit_card" />
            </li>
            <li>
              <FormattedMessage id="list_school_id" />
            </li>
            <li>
              <FormattedMessage id="list_medical_marijuana" />
            </li>
            <li>
              <FormattedMessage id="list_concealed_licence" />
            </li>
            <li>
              <FormattedMessage id="list_expierd_forms" />
            </li>
          </ul>
        </section>
        <div className="state-section-container">
          <p className="state-title">
            <FormattedMessage id={`state_title`} values={{ ...richText }}/>
          </p>
          <div>
            <FormattedMessage id={`state_number`} values={{ ...richText }}/>
          </div>
          <div>
            <FormattedMessage id={`state_additional_number`} />
          </div>
          <div>
            <FormattedMessage id={`state_timing`} />
          </div>
        </div>
        <div className="state-section">
          <div>
            <FormattedMessage id={`state_name`}/>
          </div>
          <div>
            <FormattedMessage id={`state_timing_para`} values={{ ...richText }} />
          </div>
        </div>
        <div className="state-section">
        <Email swa={stateParams.swa}/>
        </div>
      </section>
    </section>
  );
};
