import { TECHNICAL_REPORTS_ROWS } from "../constants";
import { BASE_URL } from "../utils/utils";

const mapIPPReportData = apiData => {
  const mappedData = apiData.map(fields => {
    const obj = {
      email: "@",
      claim: "#"
    };
    obj.id1 = fields.primaryIdType;
    obj.id2 = fields.secondaryIdType;

    obj.actualEmail = fields.emailAddress;
    obj.actualClaim = fields.enrollmentCode;
    obj.legalAddress = fields.legalAddress;
    obj.postOfficeAddress = fields.ProofingPOAddress;
    obj.expirationDate = fields.expirationDate;
    obj.proofDate = fields.transactionEndDateTime;
    obj.status = fields.status;
    obj.firstName = fields.firstName;
    obj.lastName = fields.lastName;
    return obj;
  });
  return mappedData;
};
export const getIPPReportTableData = showTechnicalSupport => {
  return new Promise(async resolve => {
    try {
      if(!showTechnicalSupport){
        const response = await fetch(
          `https://${BASE_URL}/api/report/?name=IPPVerification`
        );
        const data = await response.json();
        const updatedData =  data.map((row) => {
          if(row && row.status && row.status.includes("passed")){
            row.status = "Passed";
          }else{
            row.status = "Failed";
          }
          return row;
        })
        resolve(mapIPPReportData(updatedData));
      }else{
        resolve(TECHNICAL_REPORTS_ROWS);
      }
    } catch (err) {
      if (showTechnicalSupport) {
        resolve(TECHNICAL_REPORTS_ROWS);
      } else {
        resolve([]);
      }
    }
  });
};
