
export const REPORT_COLUMNS = [
  {
    key: "firstName",
    label: "First Name"
  },
  {
    key: "lastName",
    label: "Last Name"
  },
  {
    key: "legalAddress",
    label: "Legal Address"
  },
  {
    key: "postOfficeAddress",
    label: "Post Office Address"
  },
  {
    key: "expirationDate",
    label: "Expiration Date"
  },
  {
    key: "status",
    label: "Status"
  },
  {
    key: "proofDate",
    label: "Proof Date"
  },
  {
    key: "email",
    label: "Email"
  },
  {
    key: "claim",
    label: "Code"
  },
  {
    key: "id1",
    label: "ID1"
  },
  {
    key: "id2",
    label: "ID2"
  }
];

export const DOWNLOAD_REPORT_COLUMNS = REPORT_COLUMNS.map(column => {
  column.key =
    column.key === "email"
      ? "actualEmail"
      : column.key === "claim"
      ? "actualClaim"
      : column.key;
  return column;
});

export const REPORTS_ROWS = [
  {
    firstName: "John",
    lastName: "bca",
    legalAddress: "9750 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "28 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Abc",
    lastName: "Smith",
    legalAddress: "9350 Dogwood, St.Cherry Hill, NJ 08103",
    postOfficeAddress: "28 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "John",
    lastName: "Sore",
    legalAddress: "1750 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "1134 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Michael",
    lastName: "Smith",
    legalAddress: "7654 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "1874 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Pass",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Geoff",
    lastName: "Vandy",
    legalAddress: "9432 Dogwood, St.Cherry Hill, NJ 12278",
    postOfficeAddress: "9647 North Joy Ridge Drive, North Brunswick, NJ 23228",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Maddi",
    lastName: "Thompson",
    legalAddress: "5346 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "1844 North Joy Ridge Drive, North Brunswick, NJ 23225",
    expirationDate: "9/25/2022",
    status: "Pass",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Dewayne",
    lastName: "Walker",
    legalAddress: "6539 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "7645 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Polly",
    lastName: "Johnson",
    legalAddress: "4421 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "2766 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Chelsea",
    lastName: "Sandy",
    legalAddress: "9750 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "28 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "John",
    lastName: "Smith",
    legalAddress: "9750 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "28 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "John",
    lastName: "Smith",
    legalAddress: "9750 Dogwood, St.Cherry Hill, NJ 08003",
    postOfficeAddress: "28 North Joy Ridge Drive, North Brunswick, NJ 08902",
    expirationDate: "9/25/2022",
    status: "Failed",
    proofDate: "9/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  },
  {
    firstName: "Ad",
    lastName: "bh",
    legalAddress: "9401 Dogwood, St.Cherry Hill, NJ 23294",
    postOfficeAddress: "29 North Joy Ridge Drive, North Brunswick, NJ 20010",
    expirationDate: "9/15/2022",
    status: "Pass",
    proofDate: "7/25/2022",
    email: "@",
    claim: "#",
    id1: "DL",
    id2: "PSPT"
  }
];


export const TECHNICAL_REPORT_COLUMNS = [
  {
    key: "enrollCode",
    label: "Enroll Code"
  },
  {
    key: "reqJson",
    label: "Req JSON"
  },
  {
    key: "resJson",
    label: "Resp JSON"
  },
  {
    key: "timeSubmitted",
    label: "Time Submitted"
  },
  {
    key: "timeRecived",
    label: "Time Received"
  },
  {
    key: "timeCreated",
    label: "Time Created"
  },
  {
    key: "timeUpdated",
    label: "Time Updated"
  },
  {
    key: "email",
    label: "Email"
  }
];

export const DOWNLOAD_TECHNICAL_REPORT_COLUMNS = TECHNICAL_REPORT_COLUMNS.map(
  column => {
    column.key =
      column.key === "enrollCode"
        ? "enrollCode"
        : column.key === "reqJson"
        ? "reqJson"
        : column.key;
    return column;
  }
);

export const TECHNICAL_REPORTS_ROWS = [
  {
    enrollCode: "11345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "abc.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "ab.deo@gmail.com"
  },
  {
    enrollCode: "12345677",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  },
  {
    enrollCode: "12345678",
    reqJson: "@",
    resJson: "#",
    timeSubmitted: "12:23 2/23/2022",
    timeRecived: "12:23 2/23/2022",
    timeCreated: "12:23 2/23/2022",
    timeUpdated: "12:23 2/23/2022",
    email: "jhon.deo@gmail.com"
  }
];

export const STEPS = [
  {
    id: 1,
    label: "personal_info_heading_text",
    disabled: false,
  },
  {
    id: 2,
    label: "post_office_visit_text",
    disabled: true,
  },
]