import axios from 'axios';
import { BASE_URL } from '../utils/utils';

export const getCSRFToken = async () => {
  const config = {
    method: "get",
    url: `https://${BASE_URL}/api/getIppToken/`,
    headers: {
       'Accept': 'text/html',
    }
  };
  const response = await axios(config);
  const csrfToken = response && response.data.split("value=")[1].split('"')[1];
  window.csrfToken = csrfToken;
  return csrfToken;
};

const getAddressList = (payload) => {
  var config = {
    method: 'post',
    url: `https://${BASE_URL}/api/getIppFacilityList`,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': window.csrfToken,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\';'
    },
    data: payload
  };

  return axios(config);
}

const getSecondAddressList = (payload) => {
  var config = {
    method: 'post',
    url: `https://${BASE_URL}/api/applicantSecondAddress`,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': window.csrfToken,
      'Cookie': `csrftoken=${window.csrfToken}`,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\';'
    },
    withCredentials: true,
    data: payload
  }; 

  return axios(config);
}

const getOptInApplicant = payload => {
  var axios = require("axios");
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());
  var config = {
    method: "post",
    url: `https://${BASE_URL}/api/optInIPPApplicant`,
    headers: {
      "Content-Type": "application/json",
      "swa-xid": params.swa_xid,
      "swa": params.swa,
      "lang-cd": params.lang_cd || "en",
      'X-CSRFToken': window.csrfToken,
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\';'
    },
    withCredentials: true,
    data: payload
  };

  return axios(config);
};



const getBarcodeImage = (enrollmentCode) => {
  var config = {
    method: 'get',
    url: `https://${BASE_URL}/api/getBarcode/?enrollment=${enrollmentCode}`,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain',
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\';'
    },
  };
  return axios(config);
}


export {
  getAddressList,
  getSecondAddressList,
  getOptInApplicant,
  getBarcodeImage
}
