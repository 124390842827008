import React from "react";
import "./enrollment-email.scss";
import AddressList from "../address-list/AddressList";
import { USPSDescription } from "../usps-description/usps-description.jsx";

function EnrollmentEmail(props) {
  const {
    enrollmentCode,
    city,
    state,
    zipCode,
    streetAddress,
    barcodeImageUrl,
    postOffices
  } = props;

  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const newDate = `${month}/${day}/${year}`;

  return (
    <div className="enrollment-email-container">
      <div className="email-header">
        <p>
          Thank you for choosing to verify your identity in person for
          [Organization] access at a United States Postal Service office. <br />
          Follow each step listed below to complete the process:
        </p>
      </div>
      <div className="email-info">
        <p>
          {`1. This barcode will expire in 30 days on [${newDate}]. On or before the expiration date, visit one of the participating Premier Post Offices listed at the end of this email. Note: Not all USPS Post Offices offer In-Person Identity Verification. Only those listed at the end of this email offer the service.`}
        </p>
      </div>
      <div className="email-info">
        <p>
          2. Bring a copy of the barcode displayed below and appropriate
          identification documents (valid forms listed with barcode) with you
          when you visit a participating In-Person Identity Verification Post
          Office.
          <b>
            Identification documents must match the address displayed below.
          </b>
        </p>
      </div>
      <div className="address-text">
        <p>{streetAddress}</p>
        <p>{`${city}, ${state} ${zipCode}`}</p>
      </div>
      <div className="email-subinfo">
        <p>
          3. Notify the retail clerk you are here to complete a{" "}
          <b>[Program Name]</b>
          In-Person Identity Verification.
          <br />{" "}
          {`Note: Any prior emails/barcodes will not be valid. This email
          will be active for 30 days until [${newDate}]. If the request expires,
          you must start the process again online.`}
        </p>
      </div>
      <div className="email-message-container">
        <div className="enrollment-email-message">
          <div className="enrollment-email-note">
            This is your confirmation code for your ID verification.
            <div>
              <b>
                You will need this information if you go at your local USPS
                location.
              </b>
            </div>
          </div>
          <div className="enrollment-code">
            <b>Enrollment Code</b>
            <label className="code-number">{enrollmentCode}</label>
          </div>
        </div>
        <div className="barcode-container">
          <img
            id="barcode"
            data-test-id="barcode"
            src={barcodeImageUrl}
            alt={`barcode-${enrollmentCode}`}
          />
        </div>
      </div>
      <div className="your-address-mobile">
        <div className="uspc-heading">USPS Location</div>
        <b>Your Address: </b>
        <br />
        {streetAddress}, {city}, {state} {zipCode}
      </div>
      <AddressList
        postOffices={postOffices}
        heading={"Nearest USPS Identity Verification Facilities"}
        subHeading={
          "These are the USPS identity verification service facilities closest to your provided address. To be certain of service, call ahead."
        }
      />
      <USPSDescription />
    </div>
  );
}

export default EnrollmentEmail;
