import { useState } from "react";
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker';
import { FormattedMessage } from "react-intl";

const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Footer');

  const [about, setAbout] = useState("none");
  const [federal, setFederal] = useState("none");
  const [labor, setLabor] = useState("none");
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return <footer className="usa-footer usa-footer--big">
    <div className="grid-container" >
      <div className="grid-container usa-footer__return-to-top margin-top-2">
          <a
              onClick={scrollToTop}
              href=""
              data-testid="test"
          >
              {' '}
              <FormattedMessage id="uicp_scroll_to_top_text" />{' '}
          </a>    
        </div>
    </div>
    <div className="dol-footer-nav">
   
      <div className="grid-container">
     

      </div>
    </div>
    <div className="usa-footer__primary-section">
      <div className="grid-container">
        <div className="grid-row">
          <div className="tablet:grid-col-4">

            <section className="usa-footer__primary-content usa-footer__primary-content--collapsible border-top-0">
              <div className="display-flex tablet:display-block dol-footer-seal margin-y-2">
                <div className="dol-seal margin-right-2 tablet:margin-bottom-2">
                  <a href="https://www.dol.gov/" onClick={() => gaEventTracker('Clicked on Home Logo')} title="Home"
                  ><img
                      className="width-15"
                      src="https://www.dol.gov/themes/opa_theme/img/DOL-MasterLogo_BLUE.svg"
                      alt="United States Department of Labor"
                    /></a>
                </div>
                <div className="footer-seal-block">
                  <div className="dol-name-and-seal">
                    <span><FormattedMessage id="footer_dol_name_and_seal" /></span>
                  </div>
                  <div id="block-footeraddress">
                    <div>
                      <div className="address font-sans-2xs line-height-sans-4">
                        <div className="dol-phone-web">
                          <div>
                            <a className="usa-link" onClick={() => gaEventTracker('Clicked on www.dol.gov')} href="https://www.dol.gov/">www.dol.gov</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
          <div className="tablet:grid-col-8 margin-top-2">
            <div className="usa-accordion" aria-multiselectable="true">

              <div className="mobile-lg:grid-col-12">
                <h4 className="usa-accordion__heading">
                  <button className="usa-accordion__button TEXT-UPPER" aria-expanded={federal === 'block'} aria-controls="footer-nav-federal" role="img" aria-label="Federal Government"
                    onClick={() => { setFederal(federal === "block" ? "none" : "block"); }} ><FormattedMessage id="footer_nav_federal" /></button>
                </h4>
                <div id="footer-nav-federal" className="usa-accordion__content usa-prose" style={{ display: federal }} >
                  <ul className="usa-list usa-list--unstyled" style={{ textAlign: "left" }} >
                    <li className="usa-footer__secondary-link">
                      <a href="https://www.whitehouse.gov/" onClick={() => gaEventTracker('Clicked on Federal Government > White House')} ><FormattedMessage id="footer_secondary_link_white_house" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/coronavirus" onClick={() => gaEventTracker('Clicked on Federal Government > Coronavirus Resources')} ><FormattedMessage id="footer_secondary_link_corona_virus" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/stormrecovery" onClick={() => gaEventTracker('Clicked on Federal Government > Severe Storm and Flood Recovery Assistance')} ><FormattedMessage id="footer_secondary_link_storm_recovery" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/disasterrecovery" onClick={() => gaEventTracker('Clicked on Federal Government > Disaster Recovery Assistance')} ><FormattedMessage id="footer_secondary_link_disaster_recovery" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.disasterassistance.gov/" onClick={() => gaEventTracker('Clicked on Federal Government > DisasterAssistance.gov')} >DisasterAssistance.gov</a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.usa.gov/" onClick={() => gaEventTracker('Clicked on Federal Government > USA.gov')} >USA.gov</a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a
                        onClick={() => gaEventTracker('Clicked on Federal Government > No Fear Act Data')}
                        href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/resports/notification-and-federal-employee-antidiscrimination-retaliation-act-of-2002"
                      ><FormattedMessage id="footer_secondary_link_no_fear_act" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a onClick={() => gaEventTracker('Clicked on Federal Government > U.S. Office of Special Counsel')} href="https://osc.gov/"><FormattedMessage id="footer_secondary_link_special_counsel" /></a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="mobile-lg:grid-col-12">
                <h4 className="usa-accordion__heading">
                  <button className="usa-accordion__button TEXT-UPPER" aria-expanded={labor === 'block'} aria-controls="footer-nav-labor" role="img" aria-label="Labor Department" onClick={() => { setLabor(labor === "block" ? "none" : "block") }} ><FormattedMessage id="footer_nav_labor" /></button>
                </h4>
                <div id="footer-nav-labor" className="usa-accordion__content usa-prose" style={{ display: labor }} >
                  <ul className="usa-list usa-list--unstyled" style={{ textAlign: "left" }} >
                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/aboutdol" onClick={() => gaEventTracker('Labor Department > About DOL')} ><FormattedMessage id="footer_secondary_link_about_dol" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/guidance" onClick={() => gaEventTracker('Labor Department > Guidance Search')} ><FormattedMessage id="footer_secondary_link_guidance_search" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/topic/spanish-speakingtopic" onClick={() => gaEventTracker('Labor Department > Espanol')} >Espa&#241;ol</a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.oig.dol.gov/" onClick={() => gaEventTracker('Labor Department > Office of Inspector General')} ><FormattedMessage id="footer_secondary_link_inspector_general" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a
                        onClick={() => gaEventTracker('Labor Department > Subscribe to the DOL Newsletter')}
                        href="https://public.govdelivery.com/accounts/USDOL/subscriber/new?topic_id=USDOL_167"
                      ><FormattedMessage id="footer_secondary_link_dol_newsletter" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/newsroom/newsletter" onClick={() => gaEventTracker('Labor Department > Read the DOL Newsletter')} ><FormattedMessage id="footer_secondary_link_read_newsletter" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/easl" onClick={() => gaEventTracker('Labor Department > Emergency Accountability Status Link')} ><FormattedMessage id="footer_secondary_link_accountability_status" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/siteindex" onClick={() => gaEventTracker('Labor Department > A to Z Index')} ><FormattedMessage id="footer_secondary_link_a_to_z_index" /></a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="mobile-lg:grid-col-12">
                <h4 className="usa-accordion__heading">
                  <button className="usa-accordion__button TEXT-UPPER" aria-expanded={about === 'block'} aria-controls="footer-nav-about" role="img" aria-label="About the Site"
                  onClick={() => { setAbout(about === "block" ? "none" : "block") }} ><FormattedMessage id="footer_nav_about" /></button>
                </h4>
                <div id="footer-nav-about" className="usa-accordion__content usa-prose" style={{ display: about }} >
                  <ul className="usa-list usa-list--unstyled" style={{ textAlign: "left" }} >
                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/foia" onClick={() => gaEventTracker('About the Site > Freedom of Information Act')} ><FormattedMessage id="footer_secondary_link_information_act" /></a>
                    </li>
                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/disclaim" onClick={() => gaEventTracker('About the Site > Disclaimers')} ><FormattedMessage id="footer_secondary_link_disclaimers" /></a>
                    </li>

                    <li className="usa-footer__secondary-link">
                      <a href="https://www.dol.gov/general/aboutdol/accessibility" onClick={() => gaEventTracker('About the Site > Accessibility Statement')} ><FormattedMessage id="footer_secondary_link_accessibility_statement" /></a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="usa-footer__secondary-section">
      <div className="grid-container">
        <div className="grid-row">
          <div className="desktop:grid-col-6 tablet:grid-col-6">
            <div className="grid-row">
              <div className="dol-connect font-sans-2xs tablet-lg:grid-col-4 desktop:grid-col-3"><FormattedMessage id="footer_connect_font" /></div>
              <div className="grid-row grid-gap-1">

                <div className="grid-col-auto">
                  <a
                    className="usa-social-link usa-social-link--facebook circle-6"
                    href="https://www.facebook.com/departmentoflabor"
                    title="Department of Labor Facebook"
                    onClick={() => gaEventTracker('Social Link > Facebook')}
                  >
                     <img
                      src="static/img/usa-icons/facebook.svg"
                      alt="Facebook"
                    />
                    <span style={{ fontSize : '10px' }}>Facebook</span>
                  </a>
                </div>

                <div className="grid-col-auto">
                  <a
                    className="usa-social-link usa-social-link--twitter circle-6"
                    href="https://twitter.com/USDOL"
                    title="Department of Labor Twitter"
                    onClick={() => gaEventTracker('Social Link > Twitter')}
                  >
                    <img
                      src="static/img/usa-icons/twitter.svg"
                      alt="Twitter"
                    />
                    <span style={{ fontSize : '10px' }}>Twitter</span>
                  </a>
                </div>

                <div className="grid-col-auto">
                  <a
                    className="usa-social-link usa-social-link--youtube circle-6"
                    href="https://www.youtube.com/user/USDepartmentofLabor"
                    title="Department of Labor YouTube"
                    onClick={() => gaEventTracker('Social Link > Youtube')}
                  >
                    <img
                      src="static/img/usa-icons/youtube.svg"
                      alt="YouTube"
                    />
                    <span style={{ fontSize : '10px' }}>YouTube</span>
                  </a>
                </div>

                <div className="grid-col-auto">
                  <a
                    className="usa-social-link usa-social-link--instagram circle-6"
                    href="https://www.instagram.com/USDOL/"
                    title="Department of Labor Instagram"
                    onClick={() => gaEventTracker('Social Link > Instagram')}
                  >
                     <img
                      src="static/img/usa-icons/instagram.svg"
                      alt="Instagram"
                    />
                    <span style={{ fontSize : '10px' }}>Instagram</span>
                  </a>
                </div>

                <div className="grid-col-auto">
                  <a
                    className="usa-social-link usa-social-link--linkedin circle-6"
                    href="https://www.linkedin.com/company/u-s-department-of-labor/"
                    title="Department of Labor LinkedIn"
                    onClick={() => gaEventTracker('Social Link > LinkedIn')}
                  >
                    <img
                      src="static/img/usa-icons/linkedin.svg"
                      alt="LinkedIn"
                      style={{ marginBottom: '3px'}}
                    />
                    <span style={{ fontSize : '10px' }}>LinkedIn</span>
                  </a>
                </div>                
              </div>
            </div>
          </div>
          <div className="desktop:grid-col-6 tablet:grid-col-6">
            <div className="dol-util-links">
              <div className="font-sans-3xs">
                <ul className="usa-list usa-list--unstyled">
                  <li><a href="https://www.dol.gov/general/findit" className="usa-link" onClick={() => gaEventTracker('Sitemap')} ><FormattedMessage id="footer_findit" /></a></li>
                  <li><a href="https://www.dol.gov/general/aboutdol/website-policies" className="usa-link" onClick={() => gaEventTracker('Important Website Notices')} ><FormattedMessage id="footer_website_policies" /></a></li>
                  <li><a href="https://www.dol.gov/general/privacynotice" className="usa-link" onClick={() => gaEventTracker('Privacy & Security Statement')} ><FormattedMessage id="footer_privacy_notice" /></a></li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </footer>
};

export default Footer;
