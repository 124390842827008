export default {
  //App level components texts
  header_text: "Verifique su identidad en una oficina de correos postal",
  header_text_sup: " ",
  enrollment_component_heading: "Próximos pasos para verificar su identidad",
  reports_component_heading: "Informe de verificación en ,persona de USPS",
  updatedTechnicalHeader_text:"USPS in person verification technical report",
  of_text: "de",
  back_text: "ATRÁS",

  //Enrollment component
  post_office_visit_text: "Confirmación de visita a la oficina de correos",
  enrollment_heading_text: "Visite la oficina de correos antes de o para el dia",
  enrollment_description_para1:
    "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a la oficina de correos. También puede mostrar una imagen de la pantalla del código de barras.",
  enrollment_code_bold_text: "<b>Codigo de inscripción</b>",
  enrollment_description_para2_boldText:
    " Su código de barras y su inscripción vencerán después de 7 días.",
  enrollment_description_para2:"",
  print_this_pageBtn_text: "Imprimir esta página",
  enrollment_different_verification_method_link:
    "O seleccione un método de verificación diferente",
  enrollment_personal_information_text: "Información personal",
  enrollment_id_details_name_bold_text: "Nombre:",
  enrollment_id_details_email_bold_text: "Dirección de correo electrónico:",
  enrollment_id_details_address_bold_text: "Dirección:",
  enrollment_id_details_editicon_text: "Editar",
  USPS_facilities_located_around: "Oficinas de Correos cerca de:",

  //AddressSearch component
  personal_info_heading_text: "Información personal",
  heading_helper_text:
    "Escriba su nombre y dirección exactamente como aparecen en su documento de identidad emitido por el gobierno estatal o federal.",
  heading_helper_text_link: " ",
  form_required_info_text: "Los campos obligatorios a continuación están marcados con un asterisco",
  input_first_name: "Nombre",
  input_first_name_placeholder: " ",
  input_last_name: "Apellido",
  input_last_name_placeholder: " ",
  input_email: "Dirección de correo electrónico",
  input_email_placeholder: " ",
  input_street_address_1: "Dirección/nombre de la calle",
  input_street_address_1_placeholder: " ",
  input_street_address_2: "Numero de apartamento, suite, unidad, etc.",
  input_street_address_2_placeholder: " ",
  input_city_name_1: "Ciudad",
  input_city_name_placeholder: " ",
  input_state_name: "Estado",
  input_state_name_placeholder: " ",
  Select_State_DropdownText: "Seleccione su estado",
  input_zipcode: "Código postal",
  input_zipcode_placehoder: " ",
  form_submit_button: "Someter",
  different_verification_method_link:
    "O seleccione un metodo de verificacion diferente",

  //usps-description component
  required_Identity_documents: " ",
  acceptable_forms_of_ID: "Documentos de Identificación aceptables:",
  State_drivers_license: "Licencia de conducir.",
  state_id_card: "Tarjeta de identificación emitida por su estado.",
  uniformed_id_cards:
    " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación).",
  secondary_id: "Pasaporté estadunidense (necesita otra identificación).",
  required_docs_heading: "Utilizamos la dirección declarada en su identificación para verificar su identidad.",
  required_docs_para:
    "Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo:",
  reqired_docs_list_lease: "Contrato de arrendamiento, hipoteca, escritura del domicilio o el fideicomiso",
  reqired_docs_list_voter: "Tarjeta de registro de votantes",
  reqired_docs_list_vehical: "Tarjeta de registro del vehículo",
  reqired_docs_list_home: "Póliza de seguro de hogar o vehículo",
  postOffice_text: "La oficina de correos ",
  postOffice_span: "no aceptará:",
  list_social_security: "Tarjetas de seguro social",
  list_credit_card: "Tarjetas de crédito o facturas 'de utilidades'",
  list_school_id: "Identificación de escuelas",               
  list_medical_marijuana: "Tarjetas de marihuana medicinal",
  list_concealed_licence: "Licencias de portacion de armas ocultas",
  list_expierd_forms: "Formas de identificación caducadas (expiradas)",
  state_title: " ",
  state_number: " ",
  state_name: "Horario Telefonico",
  state_timing_para: "Lunes a Viernes, 8:00 a.m. - 3:30 p.m., Hora Central",
  state_email: " ",
  uicp_scroll_to_top_text: 'Volver al principio',

  //ErrorPage component
  invalid_url_heading: "URL invalida",
  invalid_url_para1:
    "Parece que la URL que ingresó no es válida o ya no existe. No podemos ubicar su número de verificación de identificación asociado con su solicitud de desempleo.",
  invalid_url_para2:
    "Es posible que la URL en su confirmación se haya ingresado incorrectamente. <b>Vuelva a verificar la URL y vuelva a intentarlo. </b>",
  invalid_url_para3: " ",
  hitawall_heading: "Tenemos problemas para conectar con USPS",
  hitawall_para1:
    "La verificación de su identidad con USPS no está disponible actualmente.",
  hitawall_para2:
    "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.",
  hitawall_para3: "Vuelva a intentarlo más tarde o elija un método de verificación diferente. Gracias por su paciencia y comprensión.",
  hitawall_para4:"",
  out_of_network_heading: "No se encontró una oficina del correo postale participantes",
  out_of_network_para1:
    "No hay Post Offices<sup>TM</sup> participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
  out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
  out_of_network_para3: "Si la información que ingresó es correcta y coincide con su identificación emitida por el gobierno, puede elegir un método de verificación diferente.",
  out_of_network_para4: "",

  // More info Component
  more_info_txt: " ",
  moreinfo_link: " ",

  // Farud Prevention Modal
  fraud_modal_header: "Bienvenido a la página de verificación de identificación del Departamento de Trabajo de los Estados Unidos.",
  fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Centro de Contacto del Seguro de Desempleo.",
  fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Centro de Contacto del Seguro de Desempleo, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Centro de Contacto del Seguro de Desempleo siguiendo los pasos en www.dol.gov/fraud.",
  fraud_modal_button: "Entiendo",
  
  // Address Modal
  address_modal_header: "Enter new address",
  address_modal_subheader: "Search for USPS facilities to verify your identity in a different location.",
  address_modal_zip: "Zip",
  address_modal_search_button: "Search",

  //FE Validation Errors
  fn_mainError: "Por favor, introduzca su nombre",
  fn_subError: "Caracteres permitidos A-Z {br} También puede incluir guiones, apóstrofes y espacios",
  ln_mainError: "Por favor introduzca su apellido",
  ln_subError: "Caracteres permitidos A-Z {br} También puede incluir espacios, puntos, guiones y apóstrofes",
  email_mainError: "Su correo electrónico debe tener un formato de correo electrónico válido",
  email_subError: "Introduzca su dirección de correo electrónico en formato: sunombre@ejemplo.com",
  addr1_mainError: "Por favor introduzca su dirección legal válida",
  addr1_subError: "Caracteres permitidos A-Z, 1-9 {br} También puede incluir espacios, guiones, apóstrofes, puntos, barra inclinada “/” y signo de número “#”",
  addr2_mainError: "Caracteres permitidos A-Z, 1-9 {br} También puede incluir espacios, guiones, apóstrofes, puntos, barra inclinada “/” y signo de número “#”",
  city_mainError: "Por favor introduzca su ciudad",
  city_subError: "Caracteres permitidos A-Z {br} También puede incluir espacios, puntos, guiones y apóstrofes",
  zipcode_mainError: "Por favor introduzca su código postal",
  zipcode_subError: "<b>Por favor introduzca su código postal</b> {br} Hasta 10 dígitos numéricos, incluyendo los guiones",
  state_mainError: "Seleccione su estado de las opciones a continuación",  
  //Header Component
  banner_header_text:"Un sitio web oficial del Gobierno de Estados Unidos",
  banner_button_text:"Por esto sabes que lo es",
  header_media_block: "Los sitios web oficiales usan .gov",
  header_media_block_body: "Los sitios web .gov seguros usan HTTPS",
  header_usdol_title: "Departamento del Trabajo de EE. UU.",
  banner_lock_description_two: "significa que se ha conectado en forma segura al sitio web .gov. Comparta información sensible solo en sitios web oficiales y seguros.",
  banner_lock_description_main: "un candado cerrado",
  banner_lock_title: "Cerrar",
  banner_lock_description_one: "o",
  header_media_block_one: "candado",
  header_media_block_two: "Un sitio web",
  header_media_block_two_inner: "Un",
  header_media_block_three: "pertenece a una organización oficial de gobierno en Estados Unidos.",

  //Footer Component
  footer_nav_federal: "Gobierno federal",
  footer_nav_labor: "Departamento del Trabajo",
  footer_nav_about: "Acerca del sitio",
  footer_connect_font: "Conectarse con el Departamento del Trabajo",
  footer_findit: "Mapa del sitio",
  footer_website_policies: "Avisos importantes del sitio web",
  footer_privacy_notice: "Declaración de privacidad y seguridad",
  footer_dol_name_and_seal: "Departamento del Trabajo de EE. UU.",

  footer_secondary_link_white_house: "casa Blanca",
  footer_secondary_link_corona_virus: "Recursos de coronavirus",
  footer_secondary_link_storm_recovery: "Asistencia para la Recuperación de Tormentas Severas e Inundaciones",
  footer_secondary_link_disaster_recovery: "Asistencia de recuperación de desastres",
  footer_secondary_link_no_fear_act: "Datos de la Ley Sin Miedo",
  footer_secondary_link_special_counsel: "Oficina de Asesoría Especial de EE. UU.",
  footer_secondary_link_about_dol: "Acerca de DOL",
  footer_secondary_link_guidance_search: "Búsqueda de orientación",
  footer_secondary_link_inspector_general: "Oficina del Inspector General",
  footer_secondary_link_dol_newsletter: "Suscríbase al boletín de DOL",
  footer_secondary_link_read_newsletter: "Lea el boletín informativo del DOL",
  footer_secondary_link_accountability_status: "Enlace de estado de responsabilidad de emergencia",
  footer_secondary_link_a_to_z_index: "Índice de la A a la Z",
  footer_secondary_link_information_act: "Acta de Libertad de Información",
  footer_secondary_link_disclaimers: "Descargos de responsabilidad",
  footer_secondary_link_accessibility_statement: "Declaración de accesibilidad",
};