import React, { useState } from "react";
import "./forgot-password.scss";
import FederalFooter from "../federal-footer/FederalFooter";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";


function ForgotPassword() {
  const gaEventTracker = useAnalyticsEventTracker('Forgot Password');
  const [emailAddress, setEmailAddress] = useState("");
  return (
    <div className="forgot-password">
      <div className="wrapper">
        <div className="heading">Forgot Password</div>
        <div className="sub-heading">Enter your DOL email address</div>
        <div className="input-field">
          <label htmlFor="email-address">Email address</label>
          <input
            id="email-address"
            name="email-address"
            data-test-id="email-address"
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
          />
        </div>
        <div className="modal-sign-in-container">
          <button
            id="reset-password"
            className="reset-password-btn"
            data-test-id="reset-password"
            onClick={() => gaEventTracker('Reset Password Button')}
          >
            Reset Password
          </button>
        </div>
      </div>
      <div className="footer">
        <FederalFooter />
      </div>
    </div>
  );
}

export default ForgotPassword;
