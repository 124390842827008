export default {
  AL: {
    name: "Alabama",
    stateCode: "AL",
  },
  AK: {
    name: "Alaska",
    stateCode: "AK",
    state_regex: /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/,
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Alaska Unemployment Insurance through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Alaska Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Alaska Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "<b>Alaska Unemployment Insurance</b>",
    state_number: " (907) 269-4700 or (907) 465-5552;",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "CLOSED on State Holidays.",
    state_timing: "Monday to Friday from 10:00 a.m. to 3:00p.m.",
    state_email: "https://labor.alaska.gov/unemployment/call-centers.htm",
    state_email_link: "https://labor.alaska.gov/unemployment/call-centers.htm",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly.{br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "If you need help verifying your identity for your <b>Alaska unemployment insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays",
    hitawall_para4: "If you need help verifying your identity for your <b>Alaska unemployment insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays",
    out_of_network_para4: "If you need help verifying your identity for your <b>Alaska unemployment insurance claim, (907) 269-4700 or (907) 465-5552;</b>{br}Monday to Friday from 10:00 a.m. to 3:00 p.m.{br}CLOSED on State Holidays",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguro de desempleo de Alaska a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Seguro de desempleo de Alaska, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Seguro de desempleo de Alaska siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      different_verification_method_link:
      "O seleccione un método de verificación diferente",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "<b>Seguro de desempleo de Alaska</b>",
      state_number: "(907) 269-4700 o (907) 465-5552;",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "CERRADO los días festivos estatales.",
      state_timing: "Lunes a viernes de 10:00 a 15:00 horas.",
      state_email: "https://labor.alaska.gov/unemployment/call-centers.htm",
      state_email_link: "https://labor.alaska.gov/unemployment/call-centers.htm",

      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Si necesita ayuda para verificar su identidad para su reclamo de <b>seguro de desempleo de Alaska, (907) 269-4700 o (907) 465-5552;</b>{br}Lunes a viernes de 10:00 a 15:00 horas.{br}CERRADO los días festivos estatales",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Si necesita ayuda para verificar su identidad para su reclamo de <b>seguro de desempleo de Alaska, (907) 269-4700 o (907) 465-5552;</b>{br}Lunes a viernes de 10:00 a 15:00 horas.{br}CERRADO los días festivos estatales",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Si necesita ayuda para verificar su identidad para su reclamo de <b>seguro de desempleo de Alaska, (907) 269-4700 o (907) 465-5552;</b>{br}Lunes a viernes de 10:00 a 15:00 horas.{br}CERRADO los días festivos estatales",
    }
  },
  AS: {
    name: "American Samoa",
    stateCode: "AS",
  },
  AZ: {
    name: "Arizona",
    stateCode: "AZ",
  },
  AR: {
    name: "Arkansas",
    stateCode: "AR",
    chooseDifferentVerificationButton_USPS: "true",
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Arkansas Division of Workforce Services through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Arkansas Division of Workforce Services, <b>please file a report</b> of potential unemployment identity fraud with Arkansas Division of Workforce Services by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //english
    //Enrollment component
    enrollment_description_para2: "   Afterward, you will need to visit an Arkansas Workforce Center to finalize your claim. Failing to respond may result in a delay or denial of benefits, or require you to repay any benefits you’ve received from the Arkansas Division of Workforce Services.",
    //usps-description component
    state_title: "<b>Arkansas Division of Workforce Services</b>",
    state_number: "1-844-908-2178",
    state_additional_number: " ",
    state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{7}-[0-9]{9}$/,
    state_timing: " ",
    state_email: "ADWS.UI.Hotline@arkansas.gov",
    state_email_link: "mailto:ADWS.UI.Hotline@arkansas.gov",
    enrollment_calculation_date: 7,
    //ErrorPage component
    invalid_url_para3: "If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).",
    hitawall_para4: "If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m - 3:30 p.m. Central Time).",
    out_of_network_para4: "If you need help verifying your identity for your Arkansas unemployment claim, please call the <b>ADWS UI Hotline at 1-844-908-2178</b> (Monday to Friday, 8:00 a.m. - 3:30 p.m. Central Time).",
    //spanish
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Arkansas Division of Workforce Services a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Arkansas Division of Workforce Services, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Arkansas Division of Workforce Services siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",
      
      //Enrollment component
      enrollment_description_para2: "  Deberá visitar un Centro de la Fuerza Laboral de Arkansas para finalizar su reclamación. La falta de respuesta puede demorar o denegar los beneficios, o requerir que usted pague los beneficios que ya recibió.",
      //usps-description component
      state_title: "<b>Arkansas Division of Workforce Services</b>",
      state_number: "1-844-908-2178",
      state_additional_number: " ",
      state_email: "ADWS.UI.Hotline@arkansas.gov",
      //ErrorPage component
      invalid_url_para3: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178</b> (de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central).",
      hitawall_para4: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178</b> (de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central).",
      out_of_network_para4: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Arkansas, llame a la <b>línea directa de UI de ADWS al 1-844-908-2178</b> (de lunes a viernes, de 8:00 a. m. a 3:30 p. m., hora central).",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
    }
  },
  CA: {
    name: "California",
    stateCode: "CA",
  },
  CO: {
    name: "Colorado",
    stateCode: "CO",
    state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{10}-[0-9]{6}$/,
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Colorado Unemployment Insurance Division through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Colorado Unemployment Insurance Division, <b>please file a report</b> of potential unemployment identity fraud with Colorado Unemployment Insurance Division by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",
    
    //english
      //Enrollment component
      enrollment_description_para1: "You’re about to confirm your identity for your <b>unemployment claim</b> with USPS. You will receive a copy of this via email. Bring this page or the email with you to the Post Office<sup>TM</sup>. You can also show a screenshot of the barcode.",  
      enrollment_description_para2_boldText: " Your barcode and enrollment will expire after seven (7) days.",
      enrollment_description_para2: " If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.", 
      enrollment_calculation_date: 7,
      //usps-description component
      state_email: "https://cdle.colorado.gov/unemployment/contact-us",
      state_email_link: "https://cdle.colorado.gov/unemployment/contact-us",
      state_title: "<b>Colorado Unemployment Insurance Division</b>",
      state_number: "Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515",
      state_additional_number: "Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402",
      state_timing: "Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays.",
      state_name: " ",
      state_timing_para: " ",
      //ErrorPage component
      invalid_url_para2:
      "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
      invalid_url_para3:
      "If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:{br} Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays. {br} <co_link>coloradoui.gov</co_link>",
      hitawall_para4:
      "If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:{br} Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays. {br} <co_link>coloradoui.gov</co_link>",
      out_of_network_para4:
      "If you need help verifying your identity for your <b>Colorado unemployment claim</b>, call:{br} Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Monday to Friday from 8:00 a.m. to 4:00 p.m. CLOSED on State Holidays. {br} <co_link>coloradoui.gov</co_link>",
      es: {
        // Farud Prevention Modal
        fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Colorado a través de USPS.",
        fraud_modal_para2: "<b>Si no solicitó desempleo</b> con División de Seguro de Desempleo de Colorado, <b>presente un informe</b> de posible fraude de identidad por desempleo ante División de Seguro de Desempleo de Colorado siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

        //App level components texts
        header_text: "Verifique su identidad en una Post Office",
        header_text_sup: "TM",
        //Enrollment component
        post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
        enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
        enrollment_description_para1: "Está a punto de confirmar su identidad para su <b>reclamo de desempleo</b> con USPS. Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
        enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
        enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
        print_this_pageBtn_text: "Imprime esta página",
        USPS_facilities_located_around: "Oficinas de Post Offices cerca:",

        //usps-description component
        required_Identity_documents: "Documentos de Identificación aceptables",
        acceptable_forms_of_ID: "Formas aceptables de identificación:",
        State_drivers_license: "Licencia de conducir",
        state_id_card: "Tarjeta de identificación emitida por su estado",
        uniformed_id_cards:
          " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
        secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
        postOffice_text: "Post Office<sup>TM</sup> no aceptará:",
        postOffice_span: " ",
        list_social_security: "Tarjetas de seguro social",
        list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
        list_school_id: "Identificación de escuelas",
        list_concealed_licence: "Licencias de portación de armas ocultas",
        list_expierd_forms: "Formas de identificación caducadas (expiradas)",
      
        state_title: "<b>División de Seguro de Desempleo de Colorado</b>",
        state_number: "Denver Metro: 303-318-9000 | Toll-Free: 1-800-388-5515",
        state_additional_number: "Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402",
        state_timing: "Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales.",
        state_email: "https://cdle.colorado.gov/unemployment/contact-us",
        //ErrorPage component
        invalid_url_para2:
        "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo. </b>",
        invalid_url_para3: "Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b> {br} Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales. {br} <co_link>coloradoui.gov</co_link>",
        hitawall_para4: "Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b> {br} Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales. {br} <co_link>coloradoui.gov</co_link>",
        out_of_network_para4: "{br}Si necesita ayuda para verificar su identidad para su <b>Reclamo de desempleo de Colorado:</b> {br} Denver Metro: 303-318-9000 | Llamada gratuita al 1-800-388-5515 {br} Spanish: 303-318-9333 | Spanish Toll-Free: 1-866-422-0402 {br}Lunes a Viernes de 8:00 a 16:00 hrs. CERRADO los días festivos estatales. {br} <co_link>coloradoui.gov</co_link>",
        hitawall_para3: "Gracias por su paciencia y comprensión.",
        out_of_network_heading: "No se encontró una Post Offices participantes",
        out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
        enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
        enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
        enrollment_id_details_address_bold_text: "<b>Dirección:</b> ",
      }
  },
  CT: {
    name: "Connecticut",
    stateCode: "CT",
  },
  DE: {
    name: "Delaware",
    stateCode: "DE",
  },
  DC: {
    name: "District Of Columbia",
    stateCode: "DC",
  },
  FM: {
    name: "Federated States Of Micronesia",
    stateCode: "FM",
  },
  FL: {
    name: "Florida",
    stateCode: "FL",
  },
  GA: {
    name: "Georgia",
    stateCode: "GA",
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    state_regex: /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Georgia Department of Labor through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Georgia Department of Labor, <b>please file a report</b> of potential unemployment identity fraud with Georgia Department of Labor by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits:",
    state_number: "Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry) ",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: " ",
    state_timing: "Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.",
    state_email: "https://dol.georgia.gov/contact-us",
    state_email_link: "https://dol.georgia.gov/contact-us",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits:{br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
    hitawall_para4: "You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits:{br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
    out_of_network_para4: "You can contact the <b>Georgia Department of Labor</b> if you have questions about unemployment benefits:{br}Call toll-free (877) 709-8185 (Say a word or phrase that best describes the reason for your inquiry){br}Monday to Friday from 8:00 a.m. to 4:00 p.m. except State Holidays or Visit your local career center Monday through Friday 8:00 a.m. to 4:30 p.m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Trabajo de Georgia a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Departamento de Trabajo de Georgia, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Departamento de Trabajo de Georgia siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      different_verification_method_link:
      "O seleccione un método de verificación diferente",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:",
      state_number: "Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales, o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.",
      state_timing: " ",
      state_email: "https://dol.georgia.gov/contact-us",
      state_email_link: "https://dol.georgia.gov/contact-us",
      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales, o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales, o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Puede comunicarse con el <b>Departamento de Trabajo de Georgia</b> si tiene alguna pregunta sobre los beneficios de desempleo:{br}Llame a la línea gratuita al (877) 709-8185 (diga una palabra o frase que mejor describa el motivo de su consulta).{br}Lunes a viernes de 8:00 a. m. a 4:00 p. m., excepto los días festivos estatales, o bien Visite su Centro de Desarrollo Profesional local de lunes a viernes de 8:00 a. m. a 4:30 p. m.{br}<ga_link>https://dol.georgia.gov/contact-us</ga_link>",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
    }
  },
  GU: {
    name: "Guam",
    stateCode: "GU",
  },
  HI: {
    name: "Hawaii",
    stateCode: "HI",
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "“You’re about to <b>confirm your identity for your unemployment claim</b> with Hawaii Unemployment Insurance Division through USPS.",
    fraud_modal_para2: "“<b>If you did not file for unemployment</b> with Hawaii Unemployment Insurance Division, <b>please file a report</b> of potential unemployment identity fraud with Hawaii Unemployment Insurance Division by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //english
    //Enrollment component
    enrollment_description_para2_boldText: " Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "   If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_calculation_date: 7,
    //usps-description component
    state_email: "https://labor.hawaii.gov/ui/contact/",
    state_regex: /^[A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{12}$/,
    state_email_link: "https://labor.hawaii.gov/ui/contact/",
    state_title: "<b>Hawaii Unemployment Insurance Division</b>",
    state_number: "Toll free at (833) 901-2272 or (833) 901-2275;",
    state_additional_number: " ",
    state_timing: "Monday to Friday from 7:45 a.m. to 4:30 p.m.",
    state_name: " ",
    state_timing_para: "CLOSED on State Holidays.",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3:
      "If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> Monday to Friday from 7:45 a.m. to 4:30 p.m.  CLOSED on State Holidays or schedule an appointment at: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>",
    hitawall_para4:
      "If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> Monday to Friday from 7:45 a.m. to 4:30 p.m.  CLOSED on State Holidays or schedule an appointment at: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>",
    out_of_network_para4:
      "If you need help verifying your identity for your <b>Hawaii unemployment claim, Toll free at (833) 901-2272 or (833) 901-2275;</b> Monday to Friday from 7:45 a.m. to 4:30 p.m.  CLOSED on State Holidays or schedule an appointment at: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>",
    //spanish
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguro de Desempleo de Hawái a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con División de Seguro de Desempleo de Hawái, <b>presente un informe</b> de posible fraude de identidad por desempleo ante División de Seguro de Desempleo de Hawái siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifica su identidad en una Post Office",
      header_text_sup: "TM",
      heading_helper_text: "Ingrese su nombre y dirección exactamente como aparecen en su identificación emitida por el gobierno.",
      enrollment_component_heading: "Próximos pasos para verificar su identificación",

      //AddressSearch component
      personal_info_heading_text: "Informacion personal",
      input_first_name: "Nombre de pila",
      input_email: "Correo electrónico",
      input_street_address_1: "Dirección",
      input_street_address_2: "Apartamento, suite, unidad, etcétera.",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",

      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir estatal",
      state_id_card: "Tarjeta de identificación estatal",
      uniformed_id_cards: "Tarjeta de identificación de las fuerzas armadas o del servicio uniformado de los EE. UU. (requiere una identificación secundaria)",
      secondary_id: "Pasaporte estadounidense (requiere identificación secundaria)",
      required_docs_heading: "Usamos la dirección en su documento de identificación para la verificación.",
      required_docs_para: "Si vive en otro lugar o su identificación no tiene una dirección en la lista, también necesitará un comprobante de dirección:",
      reqired_docs_list_lease: "Arrendamiento, hipoteca o escritura de fideicomiso",
      reqired_docs_list_voter: "Credencial de elector",
      postOffice_text: "Post Office<sup>TM</sup> no aceptará:",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguridad social",
      list_credit_card: "Tarjetas de crédito o facturas de servicios públicos",
      list_school_id: "Identificación de la escuela estatal",
      list_concealed_licence: "Licencias de portación ocultas",
      list_expierd_forms: "Formas de identificación caducadas",
      state_email: "https://labor.hawaii.gov/ui/contact/",
      state_title: "<b>División de Seguro de Desempleo de Hawái</b>",
      state_number: "Llamada gratuita al (833) 901-2272 o (833) 901-2275;",
      state_additional_number: " ",
      state_timing: "Lunes a Viernes de 7:45 am a 4:30 pm",
      state_name: " ",
      state_timing_para: "CERRADO los días festivos estatales.",
      redirect_email: "https://labor.hawaii.gov/ui/contact/",

      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes del",
      enrollment_description_para1: "Recibirá una copia de esta información por correo electrónico. Lleve esta página o el correo electrónico a Post Office<sup>TM</sup>. También puede mostrar una captura de pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      print_this_pageBtn_text: "Imprime esta página",
      enrollment_personal_information_text: "Información personal",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",

      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>",
      hitawall_heading: "Parece que tenemos un problema",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Hawái, número gratuito al (833) 901-2272 o (833) 901-2275;</b> Lunes a Viernes de 7:45 am a 4:30 pm CERRADO los días festivos estatales o programe una cita en: <hi_link>https://labor.hawaii.gov/ui/appointments/</hi_link>"
    }
  },
  ID: {
    name: "Idaho",
    stateCode: "ID",
  },
  IL: {
    name: "Illinois",
    stateCode: "IL",
  },
  IN: {
    name: "Indiana",
    stateCode: "IN",
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    state_regex: /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Indiana Department of Workforce Development through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Indiana Department of Workforce Development, <b>please file a report</b> of potential unemployment identity fraud with Indiana Department of Workforce Development by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:",
    state_number: "Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "Interpretation available upon request.",
    state_timing: "Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.",
    state_email: "unemployment.in.gov",
    state_email_link: "https://www.in.gov/dwd/indiana-unemployment/",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>",
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.",
    hitawall_para4: "Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>",
    out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
    out_of_network_para4: "{br}Here's how to reach the Unemployment Insurance Division of the <b>Indiana Department of Workforce Development</b> if you have questions about unemployment benefits or other methods to verify your identity:{br}Toll-Free: 1-800-891-6499{br}TDD/TTY: 317-232-7560{br}Monday to Friday from 8:00 a.m. to 4:30 p.m. CLOSED on State Holidays.{br}Interpretation available upon request.{br}<in_link>unemployment.in.gov</in_link>",
    // More info Component
    more_info_txt: "More Information",
    moreinfo_link: "<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departartamento de Desarrollo Laboral de Indiana a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Departartamento de Desarrollo Laboral de Indiana, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Departartamento de Desarrollo Laboral de Indiana siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_description_para1:
        "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",

      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> no aceptará:",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:",
      state_number: "Llmada gratuita: 1-800-891-6499",
      state_additional_number: "TDD/TTY: 317-232-7560",
      state_timing: "Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales.",
      state_timing_para: "Interpretación disponible a pedido.",
      state_name: " ",
      state_email: "unemployment.in.gov",
      state_email_link: "https://www.in.gov/dwd/indiana-unemployment/",
      //ErrorPage component
      invalid_url_para2:
      "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b>",
      invalid_url_para3: "Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499 {br}TDD/TTY: 317-232-7560 {br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido. {br} <in_link>unemployment.in.gov</in_link>",
      hitawall_para4: "Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499 {br}TDD/TTY: 317-232-7560 {br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido. {br} <in_link>unemployment.in.gov</in_link>",
      out_of_network_para4: "Así es como comunicarse con la División de Seguro de Desempleo del <b>Departartamento de Desarrollo Laboral de Indiana</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Llmada gratuita: 1-800-891-6499 {br}TDD/TTY: 317-232-7560 {br}Lunes a Viernes de 8:00 a 16:30 hrs. CERRADO los días festivos estatales. {br}Interpretación disponible a pedido. {br} <in_link>unemployment.in.gov</in_link>",
      hitawall_para2:
        "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar con precisión las oficinas postales cercanas.",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b> ",
      // More info Component
      more_info_txt: "Más información",
      moreinfo_link: "<li><in_link>unemployment.in.gov</in_link></li><li><in_link1>dwd.in.gov</in_link1></li>",
    }
  },
  IA: {
    name: "Iowa",
    stateCode: "IA",
  },
  KS: {
    name: "Kansas",
    stateCode: "KS",
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    state_regex: /^([0-9]{16}|[0-9]{50})$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Kansas Unemployment Insurance Contact Center through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Kansas Unemployment Insurance Contact Center, <b>please file a report</b> of potential unemployment identity fraud with Kansas Unemployment Insurance Contact Center by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:",
    state_number: "(785) 575-1460 or  (800) 292-6333",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays",
    state_timing: " ",
    state_email: "www.GetKansasBenefits.gov",
    state_email_link: "https://www.getkansasbenefits.gov/",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.",
    hitawall_para4: "Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
    out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
    out_of_network_para4: "{br}Here's how to reach the <b>Kansas Unemployment Insurance Contact Center</b>{br} if you have questions about unemployment benefits or other methods to verify your identity:{br}(785) 575-1460 or  (800) 292-6333{br}Monday - 8:00 a.m. to 4:00 p.m.{br}Tuesday - 8:00 a.m. to 4:00 p.m.{br}Wednesday - 8:00 a.m. to 4:00 p.m.{br}Thursday - 8:00 a.m. to 3:15 p.m.{br}Friday - 8:00 a.m. to 4:00 p.m.{br}CLOSED on State Holidays{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
    // More info Component
    more_info_txt: "More Information",
    moreinfo_link: "<li><ks_link1>www.dol.ks.gov</ks_link1></li><li><ks_link>www.GetKansasBenefits.gov</ks_link></li>",
    //spanish
    es: {
      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",

      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Centro de Contacto del Seguro de Desempleo de Kansas a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Centro de Contacto del Seguro de Desempleo de Kansas, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Centro de Contacto del Seguro de Desempleo de Kansas siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //AddressSearch component
      personal_info_heading_text: "Informacion personal",

      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
      " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      required_docs_heading: "Utilizamos la dirección declarada en su identificación para verificar su identidad.",
      required_docs_para:
        "Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo:",
      reqired_docs_list_lease: "Contrato de arrendamiento, hipoteca, escritura del domicilio o el fideicomiso",
      reqired_docs_list_voter: "Tarjeta de registro de votantes",
      reqired_docs_list_vehical: "Tarjeta de registro del vehículo",
      reqired_docs_list_home: "Póliza de seguro de hogar o vehículo",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: `Tarjetas de crédito o facturas "de utilidades"`,
      list_school_id: "Identificación de escuelas",               
      list_medical_marijuana: "Tarjetas de marihuana medicinal",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
      state_title: "Puede comunicarse con el <b>Centro de Contacto del Seguro de Desempleo de Kansas</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:",
      state_number: "(785) 575-1460 o (800) 292-6333",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales",
      state_timing: " ",
      state_email: "www.GetKansasBenefits.gov",
      state_email_link: "https://www.getkansasbenefits.gov/",

      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes del",
      enrollment_description_para1: "Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      enrollment_different_verification_method_link: "Elija un método de verificación diferente",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      print_this_pageBtn_text: "Imprime esta página",
      enrollment_personal_information_text: "Información personal",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",

      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Puede comunicarse con el <b>Centro de Contacto del Seguro de Desempleo de Kansas</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Puede comunicarse con el <b>Centro de Contacto del Seguro de Desempleo de Kansas</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar con precisión las oficinas postales cercanas.",
      out_of_network_para4: "{br}Puede comunicarse con el <b>Centro de Contacto del Seguro de Desempleo de Kansas</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}(785) 575-1460 o (800) 292-6333{br}Lunes - 8:00 am a 4:00 pm{br}Martes - 8:00 am a 4:00 pm{br}Miércoles - 8:00 am a 4:00 pm{br}Jueves - 8:00 am a 3:15 pm{br}Viernes - 8:00 am a 4:00 pm{br}CERRADO los días festivos estatales{br}<ks_link>www.GetKansasBenefits.gov</ks_link>",
      
      // More info Component
      more_info_txt: "Más información",
    }
  },
  KY: {
    name: "Kentucky",
    stateCode: "KY",
  },
  LA: {
    name: "Louisiana",
    stateCode: "LA",
  },
  ME: {
    name: "Maine",
    stateCode: "ME",
  },
  MH: {
    name: "Marshall Islands",
    stateCode: "MH",
  },
  MD: {
    name: "Maryland",
    stateCode: "MD",
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    state_regex: /.+/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Maryland Division of Unemployment Insurance through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Maryland Division of Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Maryland Division of Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "Here's how to reach the <b>Maryland Division of Unemployment Insurance</b> if you have questions about unemployment benefits or other methods to verify your identity:",
    state_number: "Claims agent: 667-207-6520{br}Spanish: 301-313-8000 o 1-667-207-6520",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "Claims agents are available from 8:00 a.m. to 4:00 p.m., Monday through Friday. Hours may be modified during holidays.",
    state_timing: " ",
    state_email: "https://www.labor.maryland.gov/employment/unemployment.shtml",
    state_email_link: "https://www.labor.maryland.gov/employment/unemployment.shtml",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "Here's how to reach the <b>Maryland Division of Unemployment Insurance</b>{br}if you have questions about unemployment benefits or other methods to verify your identity:{br}Claims agent: 667-207-6520{br}Spanish: 301-313-8000 o 1-667-207-6520{br}Claims agents are available from 8:00 a.m. to 4:00 p.m., Monday through Friday. Hours may be modified during holidays.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused.",
    hitawall_para4: "Here's how to reach the <b>Maryland Division of Unemployment Insurance</b>{br}if you have questions about unemployment benefits or other methods to verify your identity:{br}Claims agent: 667-207-6520{br}Spanish: 301-313-8000 o 1-667-207-6520{br}Claims agents are available from 8:00 a.m. to 4:00 p.m., Monday through Friday. Hours may be modified during holidays.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
    out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
    out_of_network_para4: "{br}Here's how to reach the <b>Maryland Division of Unemployment Insurance</b>{br}if you have questions about unemployment benefits or other methods to verify your identity:{br}Claims agent: 667-207-6520{br}Spanish: 301-313-8000 o 1-667-207-6520{br}Claims agents are available from 8:00 a.m. to 4:00 p.m., Monday through Friday. Hours may be modified during holidays.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
    // More info Component
    more_info_txt: " ",
    moreinfo_link: " ",
    //spanish
    es: {
      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",

      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con la División de Seguro de Desempleo de Maryland  a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con la División de Seguro de Desempleo de Maryland. <b>presente un informe</b> de posible fraude de identidad por desempleo ante la División de Seguro de Desempleo de Maryland. siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //AddressSearch component
      personal_info_heading_text: "Informacion personal",

      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
      " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      required_docs_heading: "Utilizamos la dirección declarada en su identificación para verificar su identidad.",
      required_docs_para:
        "Si no tiene dirección o su dirección es diferente de la que esta declarada en su documento de identificación, necesita presentar comprobante de domicilio, por ejemplo:",
      reqired_docs_list_lease: "Contrato de arrendamiento, hipoteca, escritura del domicilio o el fideicomiso",
      reqired_docs_list_voter: "Tarjeta de registro de votantes",
      reqired_docs_list_vehical: "Tarjeta de registro del vehículo",
      reqired_docs_list_home: "Póliza de seguro de hogar o vehículo",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: `Tarjetas de crédito o facturas "de utilidades"`,
      list_school_id: "Identificación de escuelas",               
      list_medical_marijuana: "Tarjetas de marihuana medicinal",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
      state_title: "Así es como comunicarse con la <b>División de Seguro de Desempleo de Maryland</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:",
      state_number: "Agente de Reclamos: 667-207-6520{br}Español: 301-313-8000 o 1-667-207-6520",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "Los agentes de reclamos están disponibles de 8:00 am a 4:00 pm, de lunes a viernes. Los horarios pueden verse modificados durante los días festivos.",
      state_timing: " ",
      state_email: "https://www.labor.maryland.gov/employment/unemployment.shtml",
      state_email_link: "https://www.labor.maryland.gov/employment/unemployment.shtml",

      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_description_para1: "Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      enrollment_different_verification_method_link: "Elija un método de verificación diferente",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      print_this_pageBtn_text: "Imprime esta página",
      enrollment_personal_information_text: "Información personal",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",

      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Así es como comunicarse con la <b>División de Seguro de Desempleo de Maryland</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Agente de Reclamos: 667-207-6520{br}Español: 301-313-8000 o 1-667-207-6520{br}Los agentes de reclamos están disponibles de 8:00 am a 4:00 pm, de lunes a viernes. Los horarios pueden verse modificados durante los días festivos.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Así es como comunicarse con la <b>División de Seguro de Desempleo de Maryland</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Agente de Reclamos: 667-207-6520{br}Español: 301-313-8000 o 1-667-207-6520{br}Los agentes de reclamos están disponibles de 8:00 am a 4:00 pm, de lunes a viernes. Los horarios pueden verse modificados durante los días festivos.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar con precisión las oficinas postales cercanas.",
      out_of_network_para4: "{br}Así es como comunicarse con la <b>División de Seguro de Desempleo de Maryland</b> si tiene preguntas sobre los beneficios de desempleo u otros métodos para verificar su identidad:{br}Agente de Reclamos: 667-207-6520{br}Español: 301-313-8000 o 1-667-207-6520{br}Los agentes de reclamos están disponibles de 8:00 am a 4:00 pm, de lunes a viernes. Los horarios pueden verse modificados durante los días festivos.{br}<md_link>https://www.labor.maryland.gov/employment/unemployment.shtml</md_link>",
      
      // More info Component
      more_info_txt: " ",
      moreinfo_link: " ",
    }    
  },
  MA: {
    name: "Massachusetts",
    stateCode: "MA",
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{8}-[0-9]{6}$/,
    showExpiratationDate: "true",

    //App level components texts
    heading_helper_text: "Enter your name and residential address exactly as you’ve entered them on your unemployment insurance online application. If your government-issued ID does not have the same residential address entered on your application, you will need to bring proof of your residential address to the participating post office location which you have selected. Please see the list of documents that can be used for proof of address ",
    heading_helper_text_link: "below.",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Massachusetts Department of Unemployment Assistance (DUA) through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Massachusetts Department of Unemployment Assistance (DUA), <b>please file a report</b> of potential unemployment identity fraud with Massachusetts Department of Unemployment Assistance (DUA) by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after ",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 5,
    //usps-description component
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "For more information regarding your unemployment claim, please visit:",
    state_number: " ",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: " ",
    state_timing: " ",
    state_email: "https://www.mass.gov/orgs/department-of-unemployment-assistance",
    state_email_link: "https://www.mass.gov/orgs/department-of-unemployment-assistance",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "For more information regarding your unemployment claim, please visit:{br}<ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
    hitawall_para4: "For more information regarding your unemployment claim, please visit:{br}<ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
    out_of_network_para4: "For more information regarding your unemployment claim, please visit:{br}<ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con el Departamento de Asistencia de Desempleo (DUA) de Massachusetts a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con el Departamento de Asistencia de Desempleo (DUA) de Massachusetts, <b>presente un informe</b> de posible fraude de identidad por desempleo ante el Departamento de Asistencia de Desempleo (DUA) de Massachusetts siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      heading_helper_text: "Ingrese su nombre y dirección residencial exactamente como los ingresó en su seguro de desempleo en línea solicitud. Si su identificación emitida por el gobierno no tiene la misma dirección residencial ingresada en su solicitud, deberá llevar un comprobante de su dirección residencial a la oficina postal participante que haya seleccionado. Consulte la lista de documentos que se pueden utilizar como comprobante de domicilio ",
      heading_helper_text_link: "abajo.",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción caducarán después de la ",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
      different_verification_method_link: "Seleccione un método de verificación diferente",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portación de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
      state_title: "Para obtener más información sobre su reclamo de seguro  de desempleo, visite: <ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
      state_number: " ",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: " ",
      state_timing: " ",
      state_email: " ",
      state_email_link: " ",

      //FE Validation Errors
      zipcode_subError: "<b>Por favor introduzca su código postal</b> {br} Hasta 10 números, incluyendo los guiones",
      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Para obtener más información sobre su reclamo de seguro  de desempleo, {br}visite: <ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Para obtener más información sobre su reclamo de seguro  de desempleo, {br}visite: <ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Para obtener más información sobre su reclamo de seguro  de desempleo, {br}visite: <ma_link>https://www.mass.gov/orgs/department-of-unemployment-assistance</ma_link>",  
    }
  },
  MI: {
    name: "Michigan",
    stateCode: "MI",
  },
  MN: {
    name: "Minnesota",
    stateCode: "MN",
  },
  MS: {
    name: "Mississippi",
    stateCode: "MS",
  },
  MO: {
    name: "Missouri",
    stateCode: "MO",
  },
  MT: {
    name: "Montana",
    stateCode: "MT",
  },
  NE: {
    name: "Nebraska",
    stateCode: "NE",
  },
  NV: {
    name: "Nevada",
    stateCode: "NV",
  },
  NH: {
    name: "New Hampshire",
    stateCode: "NH",
    state_regex: /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/,
    showErrorPageButton_OutOfNetwork: "true",
    chooseDifferentVerificationButton_USPS: "true",
    showErrorPageButton_HitaWall: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with New Hampshire Works Office through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with New Hampshire Works Office, <b>please file a report</b> of potential unemployment identity fraud with New Hampshire Works Office by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //english
    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>", 
    enrollment_calculation_date: 7,
    required_Identity_documents: "Required identity documents",
    list_school_id: "State School IDs",
    //usps-description component
    state_title: "<b>New Hampshire Employment Security</b>",
    state_number: "(603) 224-3311 {nbsp}  Toll Free (800) 852-3400  TYY/TDD (800) 735-2964",
    state_additional_number: " ",
    state_timing_para: "CLOSED on State Holidays.",
    state_timing: "Monday through Friday 8:00am to 4:30pm.",
    state_email: " ",
    state_email_link: " ",
    //ErrorPage component
    invalid_url_para3: "If you need help verifying your identification <b>please call (603) 228-4019.</b> {br} Monday through Friday 8:00am to 4:30pm. {br} CLOSED on State Holidays.",
    hitawall_para4: "If you need help verifying your identification <b>please call (603) 228-4019.</b> {br} Monday through Friday 8:00am to 4:30pm. {br} CLOSED on State Holidays.",
    out_of_network_para4: "If you need help verifying your identification <b>please call (603) 228-4019.</b> {br} Monday through Friday 8:00am to 4:30pm. {br} CLOSED on State Holidays.",
    es: {
    // Farud Prevention Modal
    fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Seguros del desmpleo de New Hampshire a través de USPS.",
    fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Seguros del desmpleo de New Hampshire, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Seguros del desmpleo de New Hampshire siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",
    
    //App level components texts
    header_text: "Verifique su identidad en una Post Office",
    header_text_sup: "TM",
    personal_info_heading_text: "Informacion personal",
    // Address Search component
    different_verification_method_link:
    "O seleccione un método de verificación diferente",
    enrollment_different_verification_method_link:
    "O seleccione un método de verificación diferente",
    //Enrollment component
    post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
    enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
    enrollment_code_bold_text: "<b>Código de inscripción</b>",
    enrollment_description_para1:
"  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
    enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
    enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
    print_this_pageBtn_text: "Imprime esta página",
    USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
    enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
    enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
    enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
    //usps-description component
    required_Identity_documents: "Documentos de Identificación aceptables",
    acceptable_forms_of_ID: "Formas aceptables de identificación:",
    State_drivers_license: "Licencia de conducir",
    state_id_card: "Tarjeta de identificación emitida por su estado",
    uniformed_id_cards:
      " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
    secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
    postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
    postOffice_span: " ",
    list_social_security: "Tarjetas de seguro social",
    list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
    list_school_id: "Identificación de escuelas",
    list_concealed_licence: "Licencias de portacion de armas ocultas",
    list_expierd_forms: "Formas de identificación caducadas (expiradas)",
  
    state_title: "<b>Seguros del desmpleo de New Hampshire</b>",
    state_number: "(603) 224-3311 Número gratuito (800) 852-3400 TYY/TDD (800) 735-2964",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "CERRADO los días festivos estatales.",
    state_timing: "De lunes a viernes, de 8:00 a. m. a 4:30 p. m.",
    state_email: " ",
    state_email_link: " ",
    
    //ErrorPage component
    invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
    invalid_url_para3: "Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019.</b>{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.",
    hitawall_heading: "Tenemos problemas para conectar con USPS",
    hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
    hitawall_para3: "Gracias por su paciencia y comprensión.",
    hitawall_para4: "Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019.</b>{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.",
    out_of_network_heading: "No se encontró una Post Offices participantes",
    out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
    out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
    out_of_network_para4: "Si necesita ayuda para verificar su identificación, <b>llame al (603) 228-4019.</b>{br}Lunes a viernes de 8:00 am a 4:30 pm.{br}CERRADO los días festivos estatales.",
    }
  },
  NJ: {
    name: "New Jersey",
    stateCode: "NJ",
  },
  NM: {
    name: "New Mexico",
    stateCode: "NM",
    state_regex: /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/,
    showErrorPageButton_OutOfNetwork: "true",
    chooseDifferentVerificationButton_USPS: "true",
    showErrorPageButton_HitaWall: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with NM Department of Workforce Solutions through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with NM Department of Workforce Solutions, <b>please file a report</b> of potential unemployment identity fraud with NM Department of Workforce Solutions by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center",
    state_number: "1-877-664-6984",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: " ",
    state_timing: "Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time",
    state_email: " ",
    state_email_link: " ",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time",
    hitawall_para4: "Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time",
    out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
    out_of_network_para4: "Here's how to reach us if you have questions about unemployment benefits or need help with verifying your identity:{br}<b>NM Department of Workforce Solutions</b>{br}Unemployment Insurance Operations Center{br}1-877-664-6984{br}Monday to Friday, 8:00 a.m. - 4:30 p.m. Mountain Time",
    // More info Component
    more_info_txt: "More Information",
    moreinfo_link: "<li><nm_link1>Find my local New Mexico Workforce Connection Center</nm_link1></li><li><nm_link2>Information about Unemployment Insurance</nm_link2></li>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Soluciones de Fuerza Laboral de Nuevo México a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Departamento de Soluciones de Fuerza Laboral de Nuevo México, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Departamento de Soluciones de Fuerza Laboral de Nuevo México siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",
      
      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      // Address Search component
      different_verification_method_link:
      "O seleccione un método de verificación diferente",
      enrollment_different_verification_method_link:
      "O seleccione un método de verificación diferente",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo",
      state_number: "1-877-664-6984",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: " ",
      state_timing: "Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña",
      state_email: " ",
      state_email_link: " ",
      
      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Si necesita ayuda para verificar su identidad para su reclamo de desempleo o necesita ayuda para verificar su identidad:{br}<b>Departamento de Soluciones de Fuerza Laboral de Nuevo México</b>{br}Centro de Operaciones del Seguro de Desempleo{br}1-877-664-6984{br}Lunes a viernes, 8:00 a.m. - 4:30 p.m. Horario de Montaña",
      // More info Component
      more_info_txt: "Más información",
      moreinfo_link: "<li><nm_link1>Encontrar mi Centro de Conexión de Fuerza Laboral de Nuevo México local</nm_link1></li><li><nm_link2>Información sobre el Seguro de Desempleo</nm_link2></li>",
      }
  },
  NY: {
    name: "New York",
    stateCode: "NY",
  },
  NC: {
    name: "North Carolina",
    stateCode: "NC",
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with N.C. Division of Employment Security through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with N.C. Division of Employment Security, <b>please file a report</b> of potential unemployment identity fraud with N.C. Division of Employment Security by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //english
      //Enrollment component
      enrollment_description_para2_boldText: " Your barcode and enrollment will expire after seven (7) days.",
      enrollment_description_para2: " If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
      enrollment_calculation_date: 7,
      //usps-description component
      state_email: "des.nc.gov",
      state_email_link: "https://des.nc.gov",
      state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{10}-[0-9]{10}$/,
      state_title: "<b>N.C. Division of Employment Security</b>",
      state_number: "Toll Free at 888-737-0259.",
      state_additional_number: " ",
      state_timing: "Monday - Friday between 8 a.m. and 5 p.m.",
      state_name: " ",
      state_timing_para: "Closed on State Holidays.",
      //ErrorPage component
      invalid_url_para2:
      "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
      invalid_url_para3:
        "<b>N.C. Division of Employment Security</b> staff are available to assist you with questions about unemployment insurance benefits. {br} You may reach us at 888-737-0259. {br} Monday - Friday between 8 a.m. and 5 p.m. {br} Closed on State Holidays. {br} <nc_link>des.nc.gov</nc_link>",
      hitawall_para4:
        "<b>N.C. Division of Employment Security</b> staff are available to assist you with questions about unemployment insurance benefits. {br} You may reach us at 888-737-0259. {br} Monday - Friday between 8 a.m. and 5 p.m. {br} Closed on State Holidays. {br} <nc_link>des.nc.gov</nc_link>",
      out_of_network_para4:
        "<b>N.C. Division of Employment Security</b> staff are available to assist you with questions about unemployment insurance benefits. {br} You may reach us at 888-737-0259. {br} Monday - Friday between 8 a.m. and 5 p.m. {br} Closed on State Holidays. {br} <nc_link>des.nc.gov</nc_link>",
    //spanish 
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con División de Seguridad del Empleo de Carolina del Norte a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con División de Seguridad del Empleo de Carolina del Norte, <b>presente un informe</b> de posible fraude de identidad por desempleo ante División de Seguridad del Empleo de Carolina del Norte siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      heading_helper_text: "Escriba su nombre y dirección exactamente como aparecen en su documento de identidad emitido por el gobierno estatal o federal.",

      //AddressSearch component
      personal_info_heading_text: "Informacion personal",

      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      //usps-description component
      state_email: "des.nc.gov",
      state_title: "<b>División de Seguridad del Empleo de Carolina del Norte</b>",
      state_number: "Línea gratuita al 888-737-0259.",
      state_additional_number: " ",
      state_timing: "Lunes a viernes de 8 a.m. a 5 p.m.",
      state_name: " ",
      state_timing_para: "Cerrado los días feriados estatales.",

      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_description_para1: "Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      print_this_pageBtn_text: "Imprime esta página",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",

      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "El personal de la <b>División de Seguridad del Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo.{br}Puedo comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br} <nc_link>des.nc.gov</nc_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "El personal de la <b>División de Seguridad del Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo.{br}Puedo comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br} <nc_link>des.nc.gov</nc_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "El personal de la <b>División de Seguridad del Empleo de Carolina del Norte</b> está disponible para ayudarlo con sus preguntas sobre los beneficios por desempleo.{br}Puedo comunicarse con nosotros al 888-737-0259. {br}Lunes a viernes de 8 a.m. a 5 p.m. {br}Cerrado los días feriados estatales. {br} <nc_link>des.nc.gov</nc_link>",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
    }
  },
  ND: {
    name: "North Dakota",
    stateCode: "ND",
  },
  MP: {
    name: "Northern Mariana Islands",
    stateCode: "MP",
  },
  OH: {
    name: "Ohio",
    stateCode: "OH",
    state_regex: /^[A-Z0-9a-z\-\_]{1,64}$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Ohio Office of Unemployment Insurance through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Ohio Office of Unemployment Insurance, <b>please file a report</b> of potential unemployment identity fraud with Ohio Office of Unemployment Insurance by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    state_title: "You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m.",
    state_number: " ",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>",
    state_timing: "Monday through Friday (except holidays).",
    state_email: " ",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    //ErrorPage component
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused. Please try again later",
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly.{br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m.{br}Monday through Friday (except holidays).{br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>",
    hitawall_para4: "You can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m.{br}Monday through Friday (except holidays).{br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>",
    out_of_network_para4: "If you believe the information you have entered is correct and matches your government issued identification, you can contact the <b>Ohio Office of Unemployment Insurance</b> Operations by calling 1-877-644-6562 (or TTY 1-614-387-8408) between 8 a.m. and 5 p.m. Monday through Friday (except holidays).{br}You may also visit our website at <oh_link>unemployment.ohio.gov</oh_link>",
    // More info Component
    more_info_txt: "More Information",
    moreinfo_link: "<li><oh_link1>Unemployment Insurance | Job and Family Services</oh_link1></li><li><oh_link2>For additional information regarding required documentation please refer to The Worker’s Guide to Unemployment Insurance</oh_link2></li>",
  },
  OK: {
    name: "Oklahoma",
    stateCode: "OK",
  },
  OR: {
    name: "Oregon",
    stateCode: "OR",
    chooseDifferentVerificationButton_USPS: "true",
    showErrorPageButton_HitaWall: "true",
    showErrorPageButton_OutOfNetwork: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Oregon Employment Department through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Oregon Employment Department, <b>please file a report</b> of potential unemployment identity fraud with Oregon Employment Department by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //english
    //Enrollment component
    enrollment_description_para2_boldText: " Your barcode and enrollment will expire after seven days.",
    enrollment_description_para2: "   You still need to complete your orientation appointment with WorkSource Oregon. If you fail to respond, your benefits may be delayed or denied and OED may require you to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_calculation_date: 7,
    //usps-description component
    state_email: "unemployment.oregon.gov/contact",
    state_email_link: "https://unemployment.oregon.gov/contact",
    state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{3}-[0-9]{9}$/,
    state_title: "<b>Oregon Employment Department</b>",
    state_number: "877-345-3484 (Monday - Friday, 8 a.m. – 5 p.m.)",
    state_additional_number: " ",
    state_timing: " ",
    state_name: " ",
    state_timing_para: " ",
    //ErrorPage component
    invalid_url_para3:
      "If you need help verifying your identity for your <b>Oregon unemployment claim</b>, please call us at 877-345-3484 (Monday - Friday, 8 a.m. - 5 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>",
    hitawall_para4:
      "If you need help verifying your identity for your <b>Oregon unemployment claim</b>, please call us at 877-345-3484 (Monday - Friday, 8 a.m. - 5 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>",
    out_of_network_para4:
      "If you need help verifying your identity for your <b>Oregon unemployment claim</b>, please call us at 877-345-3484 (Monday - Friday, 8 a.m. - 5 p.m.) or send us an online message at <or_link>unemployment.oregon.gov/contact</or_link>",
    //spanish
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo de Oregon a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Departamento de Empleo de Oregon, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Departamento de Empleo de Oregon siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //Enrollment component
      enrollment_description_para2_boldText: " Su código de barras y su inscripción vencerán después de siete días.",
      enrollment_description_para2: "   Aún debe completar su cita de orientación con WorkSource Oregon. Si no responde, es posible que sus beneficios se retrasen o se nieguen y el DEO puede exigirle que pague los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      //usps-description component
      state_email: "unemployment.oregon.gov/contact",
      state_title: "<b>Departamento de Empleo de Oregon</b>",
      state_number: "877-345-3484 (Lunes a viernes, 8 a.m. - 5 p.m.)",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: " ",
      redirect_email: "https://unemployment.oregon.gov/contact",
      //ErrorPage component
      invalid_url_para3:
        "Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos <b>al 877-345-3484</b> (Lunes a viernes, 8 a.m. - 5 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>",
      hitawall_para4:
        "Si necesita ayuda para verificar su identidad para su reclamo de desempleo de Oregon, llámenos <b>al 877-345-3484</b> (Lunes a viernes, 8 a.m. - 5 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>",
      out_of_network_para4:
        "Si necesita ayuda para verificar su identidad para su <b>reclamo de desempleo de Oregon</b>, llámenos al 877-345-3484 (Lunes a viernes, 8 a.m. - 5 p.m.) o envíenos un mensaje en línea a <or_link>unemployment.oregon.gov/contact</or_link>",
      out_of_network_para2:
        "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
      enrollment_personal_information_text: "Información personal",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      
    }
  },
  PW: {
    name: "Palau",
    stateCode: "PW",
  },
  PA: {
    name: "Pennsylvania",
    stateCode: "PA",
  },
  PR: {
    name: "Puerto Rico",
    stateCode: "PR",
  },
  RI: {
    name: "Rhode Island",
    stateCode: "RI",
  },
  SC: {
    name: "South Carolina",
    stateCode: "SC",
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    state_regex: /^[0-9]{8}-[0-9]{6}-[0-9]{10}-[0-9]{10}$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with South Carolina Department of Employment and Workforce through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with South Carolina Department of Employment and Workforce, <b>please file a report</b> of potential unemployment identity fraud with South Carolina Department of Employment and Workforce by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: " ",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "<b>South Carolina Department of Employment and Workforce</b>",
    state_number: "Toll Free at 1-866-831-1724",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "Closed on State Holidays",
    state_timing: "Monday - Friday between 8 a.m. and 4:30 p.m.",
    state_email: "dew.sc.gov",
    state_email_link: "https://dew.sc.gov",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>",
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused. Please try again later.",
    hitawall_para4: "<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>",
    out_of_network_para3: " ",
    out_of_network_para4: "<b>South Carolina Department of Employment and Workforce</b> staff are available to assist with questions about unemployment insurance benefits.{br}Staff can be reached at 1-866-831-1724.{br}Monday - Friday between 8a.m. and 4:30 p.m.{br}Closed on State Holidays.{br}<sc_link>dew.sc.gov</sc_link>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con Departamento de Empleo y Fuerza Laboral de Carolina del Sur a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Departamento de Empleo y Fuerza Laboral de Carolina del Sur, <b>presente un informe</b> de posible fraude de identidad por desempleo ante Departamento de Empleo y Fuerza Laboral de Carolina del Sur siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",
      
      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: " ",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "<b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b>",
      state_number: "Línea gratuita: 1-866-831-1724",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "Cerrado los días feriados estatales.",
      state_timing: "De lunes a viernes, de 8 a.m. a 4:30 p.m.",
      state_email: "dew.sc.gov",
      state_email_link: "https://dew.sc.gov",
      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Por favor, vuelva a intentarlo más tarde.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para3: " ",
      out_of_network_para4: "El personal del <b>Departamento de Empleo y Fuerza Laboral de Carolina del Sur</b> está disponible para responder sus preguntas sobre los beneficios por desempleo.{br}Comuníquese con nosotros al 1-866-831-1724.{br} De lunes a viernes, de 8 a.m. a 4:30 p.m.{br} Cerrado los días feriados estatales.{br}<sc_link>dew.sc.gov</sc_link>",
      enrollment_different_verification_method_link: "O seleccione un método de verificación diferente",
    }
  },
  SD: {
    name: "South Dakota",
    stateCode: "SD",
  },
  TN: {
    name: "Tennessee",
    stateCode: "TN",
  },
  TX: {
    name: "Texas",
    stateCode: "TX",
    showErrorPageButton_OutOfNetwork: "false",
    showErrorPageButton_HitaWall: "false",
    chooseDifferentVerificationButton_USPS: "false",
    state_regex: /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/,
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>register to use USPS to confirm your identity for your unemployment claim</b> with Texas Workforce Commission.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with Texas Workforce Commission, <b>please file a report</b> of potential unemployment identity fraud with Texas Workforce Commission by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.",
    state_number: "You may reach us at 800-939-6631.",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: " ",
    state_timing: "Monday - Friday, except for state holidays.",
    state_email: "www.twc.texas.gov",
    state_email_link: "https://www.twc.texas.gov",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link>www.twc.texas.gov</tx_link>",
    hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused. Please try again later.",
    hitawall_para4: "<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link>www.twc.texas.gov</tx_link>",
    out_of_network_para4: "<b>Texas Workforce Commission</b> Unemployment Benefits staff are available to assist you with questions about your claim.{br}You may reach us at 800-939-6631.{br}Monday - Friday, except for state holidays.{br}<tx_link>www.twc.texas.gov</tx_link>",
    es: {
      // Farud Prevention Modal
      fraud_modal_header: "Bienvenido a la página de verificación de identidad del Departamento de Trabajo de los Estados Unidos.",
      fraud_modal_para1: "Está a punto de <b>registrarse para utilizar USPS para confirmar su identidad para su reclamo de desempleo</b> ante la Comisión de la Fuerza Laboral de Texas.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con Comisión de la Fuerza Laboral de Texas, <b>presente un informe</b> de posible fraude de identidad por desempleo ante la Comisión de la Fuerza Laboral de Texas siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",

      //App level components texts
      header_text: `Verifique su identidad en una Oficina del Correo Postal (Post Office<sup>TM</sup>)`,
      header_text_sup: " ",
      personal_info_heading_text: "Informacion personal",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a la Oficina del Correo Postal (Post Office<sup>TM</sup>)",
      enrollment_heading_text: "Visite la Oficina del Correo Postal (Post Office<sup>TM</sup>) antes de o para el dia",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a la Oficina del Correo Postal (Post Office<sup>TM</sup>). También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b> ",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        "Identificación de las Fuerzas Armadas de los EE. UU. o del servicio uniformado (requiere una identificación adicional)",
      secondary_id: "Pasaporté estadunidense (requiere una identificación adicional)",
      postOffice_text: "La Oficina del Correo Postal (Post Office<sup>TM</sup>) <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portación de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo.",
      state_number: "Puede comunicarse con nosotros al 800-939-6631.",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: " ",
      state_timing: "De lunes a viernes, excepto los días festivos estatales.",
      state_email: "www.twc.texas.gov",
      state_email_link: "https://www.twc.texas.gov",

      //ErrorPage component
      invalid_url_heading: "Enlace URL invalido",
      invalid_url_para1:
      "Parece que el enlace URL que ingresó ya no es válido o ya no existe. No podemos ubicar su número de verificación de identificación asociado con su solicitud de desempleo.", 
      invalid_url_para2: "Es posible que el enlace URL en su confirmación se haya ingresado incorrectamente. <b>Revise el enlace URL y vuelva a intentarlo.</b>",
      invalid_url_para3: "El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. {br}Puede comunicarse con nosotros al 800-939-6631. {br}De lunes a viernes, excepto los días festivos estatales. {br}<tx_link>www.twc.texas.gov</tx_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para1:
      "La verificación de su identidad con USPS no está disponible en este momento.",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Por favor, vuelva a intentarlo más tarde.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. {br}Puede comunicarse con nosotros al 800-939-6631. {br}De lunes a viernes, excepto los días festivos estatales. {br}<tx_link>www.twc.texas.gov</tx_link>",
      out_of_network_heading: "No se encontró una Oficina de Correo Postal (Post Office<sup>TM</sup>) participante",
      out_of_network_para1: "No hay una Oficina de Correo Postal Oficina (Post Office<sup>TM</sup>) participante detro de 50 millas de su direccion para completar la verificacion de identidad en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "El personal de Beneficios de Desempleo de la <b>Comisión de la Fuerza Laboral de Texas</b> está disponible para ayudarlo con preguntas sobre su reclamo. {br}Puede comunicarse con nosotros al 800-939-6631. {br}De lunes a viernes, excepto los días festivos estatales. {br}<tx_link>www.twc.texas.gov</tx_link>",
    }
  },
  UT: {
    name: "Utah",
    stateCode: "UT",
  },
  VT: {
    name: "Vermont",
    stateCode: "VT",
  },
  VI: {
    name: "Virgin Islands",
    stateCode: "VI",
  },
  VA: {
    name: "Virginia",
    stateCode: "VA",
  },
  WA: {
    name: "Washington",
    stateCode: "WA",
  },
  WV: {
    name: "West Virginia",
    stateCode: "WV",
    state_regex: /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/,
    showErrorPageButton_OutOfNetwork: "true",
    showErrorPageButton_HitaWall: "true",
    chooseDifferentVerificationButton_USPS: "true",
    // Farud Prevention Modal
    fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with WorkForce West Virginia through USPS.",
    fraud_modal_para2: "<b>If you did not file for unemployment</b> with WorkForce West Virginia, <b>please file a report</b> of potential unemployment identity fraud with WorkForce West Virginia by following the steps at <fraud_link>www.dol.gov/fraud</fraud_link>.",

    //Enrollment component
    enrollment_description_para2_boldText: "Your barcode and enrollment will expire after seven (7) days.",
    enrollment_description_para2: "If you do not verify your identity, your benefits may be delayed or denied and you may be required to repay any benefits you've received from the Unemployment Insurance Program.",
    enrollment_id_details_name_bold_text: "<b>Name:</b>",
    enrollment_id_details_email_bold_text: "<b>Email:</b>",
    enrollment_id_details_address_bold_text: "<b>Address:</b>",
    enrollment_calculation_date: 7,
    //usps-description component
    required_Identity_documents: "Required identity documents",
    state_id_card: "State ID Card",
    list_credit_card: "Credit cards or utility bills",
    list_school_id: "State School IDs",
    state_title: "If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);",
    state_number: " ",
    state_additional_number: " ",
    state_name: " ",
    state_timing_para: "CLOSED on State Holidays",
    state_timing: "Monday to Friday from 8:00am to 4:00pm",
    state_email: "https://workforcewv.org/about-workforce-wv/local-office-locations/",
    state_email_link: "https://workforcewv.org/about-workforce-wv/local-office-locations/",
    //ErrorPage component
    invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. {br}<b>Please double-check the URL and try again.</b>",
    invalid_url_para3: "If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",
    hitawall_para4: "If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",
    out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
    out_of_network_para4: "If you have questions about your unemployment claim or identity verification, contact <b>WorkForce West Virginia</b> at 1-800-252-JOBS (1-800-252-5627);{br}Monday to Friday from 8:00am to 4:00pm{br}CLOSED on State Holidays{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",
    // More info Component
    more_info_txt: "More Information",
    moreinfo_link: "<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link2>Information on unemployment in West Virginia</wv_link2></li><li><wv_link3>Your unemployment claimant handbook</wv_link3></li>",
    es: {
      // Farud Prevention Modal
      fraud_modal_para1: "Está a punto de <b>confirmar su identidad para su reclamo de desempleo</b> con WorkForce West Virginia a través de USPS.",
      fraud_modal_para2: "<b>Si no solicitó desempleo</b> con WorkForce West Virginia, <b>presente un informe</b> de posible fraude de identidad por desempleo ante WorkForce West Virginia siguiendo los pasos en <fraud_link>www.dol.gov/fraud</fraud_link>.",
      
      //App level components texts
      header_text: "Verifique su identidad en una Post Office",
      header_text_sup: "TM",
      personal_info_heading_text: "Informacion personal",
      different_verification_method_link:
        "O seleccione un método de verificación diferente",
      enrollment_different_verification_method_link:
        "O seleccione un método de verificación diferente",
      //Enrollment component
      post_office_visit_text: "Confirmación de visita a Post Office<sup>TM</sup>",
      enrollment_heading_text: "Visite Post Office<sup>TM</sup> antes de o para el dia",
      enrollment_code_bold_text: "<b>Código de inscripción</b>",
      enrollment_description_para1:
  "  Recibirá una copia de este código de inscripción por correo electrónico. Imprima el correo electrónico y tráigalo con usted a Post Office<sup>TM</sup>. También puede mostrar una imagen de la pantalla del código de barras.",
      enrollment_description_para2_boldText: "Su código de barras y su inscripción vencerán después de siete (7) días.",
      enrollment_description_para2: "Si no verifica su identidad, es posible que se retrasen o nieguen sus beneficios y es posible que deba devolver los beneficios que haya recibido del Programa de Seguro de Desempleo.",
      print_this_pageBtn_text: "Imprime esta página",
      USPS_facilities_located_around: "Oficinas de Post Offices cerca:",
      enrollment_id_details_name_bold_text: "<b>Nombre:</b>",
      enrollment_id_details_email_bold_text: "<b>Dirección de correo electrónico:</b>",
      enrollment_id_details_address_bold_text: "<b>Dirección:</b>",
      //usps-description component
      required_Identity_documents: "Documentos de Identificación aceptables",
      acceptable_forms_of_ID: "Formas aceptables de identificación:",
      State_drivers_license: "Licencia de conducir",
      state_id_card: "Tarjeta de identificación emitida por su estado",
      uniformed_id_cards:
        " Tarjeta de identificación de dependiente militar o Tarjeta de Marino Mercante de la Guardia Costera de Estados Unidos (necesita otra identificación)",
      secondary_id: "Pasaporté estadunidense (necesita otra identificación)",
      postOffice_text: "Post Office<sup>TM</sup> <u>no aceptará:</u>",
      postOffice_span: " ",
      list_social_security: "Tarjetas de seguro social",
      list_credit_card: "Tarjetas de crédito o facturas \"de utilidades\"",
      list_school_id: "Identificación de escuelas",
      list_concealed_licence: "Licencias de portacion de armas ocultas",
      list_expierd_forms: "Formas de identificación caducadas (expiradas)",
    
      state_title: "Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627); de lunes a viernes de 8:00am to 5:00pm.",
      state_number: " ",
      state_additional_number: " ",
      state_name: " ",
      state_timing_para: "Cerrado los días feriados estatales.",
      state_timing: " ",
      state_email: "https://workforcewv.org/about-workforce-wv/local-office-locations/",
      state_email_link: "https://workforcewv.org/about-workforce-wv/local-office-locations/",
      //ErrorPage component
      invalid_url_para2: "Es posible que la URL en su confirmación se haya ingresado incorrectamente. {br}<b>Vuelva a verificar la URL y vuelva a intentarlo.</b> ",
      invalid_url_para3: "Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627); de lunes a viernes de 8:00am to 5:00pm.{br}Cerrado los días feriados estatales.{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",
      hitawall_heading: "Tenemos problemas para conectar con USPS",
      hitawall_para2: "Estamos trabajando para resolver el problema lo antes posible y nos disculpamos por cualquier inconveniente que esto pueda haber causado. Vuelva a intentarlo más tarde o elija un método de verificación diferente.",
      hitawall_para3: "Gracias por su paciencia y comprensión.",
      hitawall_para4: "Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627); de lunes a viernes de 8:00am to 5:00pm.{br}Cerrado los días feriados estatales.{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",
      out_of_network_heading: "No se encontró una Post Offices participantes",
      out_of_network_para1: "No hay Post Offices participantes dentro de las 50 millas de su dirección para completar la verificación de identificación en persona con USPS en este momento.",
      out_of_network_para2: "Si cree que la dirección proporcionada a continuación es incorrecta, actualícela para que coincida con la que figura en su identificación emitida por el gobierno para poder ubicar las oficinas postales cercanas.",
      out_of_network_para4: "Si tienes preguntas sobre tu reclamo de desempleo o verificación de identidad, comunícate con <b>WorkForce West Virginia</b> al 1-800-252-JOBS (1-800-252-5627); de lunes a viernes de 8:00am to 5:00pm.{br}Cerrado los días feriados estatales.{br}<wv_link>https://workforcewv.org/about-workforce-wv/local-office-locations/</wv_link>",

      // More info Component
      more_info_txt: "Más información",
      moreinfo_link: "<li><wv_link1>Find my WorkForce West Virginia local office</wv_link1></li><li><wv_link2>Information on unemployment in West Virginia</wv_link2></li><li><wv_link3>Your unemployment claimant handbook</wv_link3></li>",
    }
  },
  WI: {
    name: "Wisconsin",
    stateCode: "WI",
  },
  WY: {
    name: "Wyoming",
    stateCode: "WY",
  }
};

export const richText = {
  b: chunks => <b>{chunks}</b>,
  br: <br />,
  nbsp: <>&nbsp;</>,
  fraud_link: chunks => <a href="https://www.dol.gov/agencies/eta/UIIDtheft">{chunks}</a>,
  hi_link: chunks => <a href="https://labor.hawaii.gov/ui/appointments/">{chunks}</a>,
  nc_link: chunks => <a href="https://des.nc.gov">{chunks}</a>,
  co_link: chunks => <a href="https://coloradoui.gov">{chunks}</a>,
  tx_link: chunks => <a href="https://www.twc.texas.gov/">{chunks}</a>,
  oh_link: chunks => <a href="https://unemployment.ohio.gov">{chunks}</a>,
  or_link: chunks => <a href="https://unemployment.oregon.gov/contact">{chunks}</a>,
  oh_link1: chunks => <a href="https://jfs.ohio.gov/job-services-and-unemployment/unemployment/overview">{chunks}</a>,
  oh_link2: chunks => <a href="https://jfs.ohio.gov/wps/portal/gov/jfs/job-services-and-unemployment/unemployment/for-unemployed-workers/resources/01-workers-guide-to-unemployment-insurance">{chunks}</a>,
  sc_link:  chunks => <a href="https://dew.sc.gov/">{chunks}</a>,
  ga_link:  chunks => <a href="https://dol.georgia.gov/contact-us">{chunks}</a>,
  ma_link:  chunks => <a href="https://www.mass.gov/orgs/department-of-unemployment-assistance">{chunks}</a>,
  ks_link:  chunks => <a href="https://www.getkansasbenefits.gov/">{chunks}</a>,
  ks_link1:  chunks => <a href="https://www.dol.ks.gov/">{chunks}</a>,
  wv_link:  chunks => <a href="https://workforcewv.org/about-workforce-wv/local-office-locations/">{chunks}</a>,
  wv_link1:  chunks => <a href="https://workforcewv.org/about-workforce-wv/local-office-locations/">{chunks}</a>,
  wv_link2:  chunks => <a href="https://workforcewv.org/unemployment-insurance-benefits/">{chunks}</a>,
  wv_link3:  chunks => <a href="https://workforcewv.org/unemployment-insurance-benefits/unemployment-resources/">{chunks}</a>,
  md_link:  chunks => <a href="https://www.labor.maryland.gov/employment/unemployment.shtml">{chunks}</a>,
  in_link:  chunks => <a href="https://www.in.gov/dwd/indiana-unemployment/">{chunks}</a>,
  in_link1:  chunks => <a href="https://www.in.gov/dwd/">{chunks}</a>,
  nm_link1:  chunks => <a href="https://www.dws.state.nm.us/en-us/Office-Locations/">{chunks}</a>,
  nm_link2:  chunks => <a href="https://www.dws.state.nm.us/en-us/Unemployment/">{chunks}</a>,
  li: (chunks) => <li>{chunks}</li>
}; 