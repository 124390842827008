import React from "react";
import { useIntl } from 'react-intl';
import { EmailTemplate } from "./EmailTemplate";

export const Email = (swa) => {
  const intl = useIntl();

  return (
    <EmailTemplate
      href={intl.formatMessage({ id: 'state_email_link' })}
      swa={swa}
    />
  );
};
