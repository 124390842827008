import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import "./table-header.scss";
import { Switch, FormControlLabel, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import {
  DOWNLOAD_REPORT_COLUMNS,
  DOWNLOAD_TECHNICAL_REPORT_COLUMNS,
  TECHNICAL_REPORTS_ROWS
} from "../../constants";
import { getCurrentDate } from "../../utils/utils";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

function Tableheader(props) {
  const gaEventTracker = useAnalyticsEventTracker("Table Header");
  const {
    onSearchStringChange,
    onToggleChange,
    showTechnicalSupport,
    data
  } = props;
  let updatedData = data;
  if (data && data.length) {
    updatedData = data.map(element => {
      element.actualClaim = `'${element.actualClaim}'`;
      return element;
    });
  }
  return (
    <>
      <div className="table-header">
        <div className="search-box">
          <input
            type="text"
            onChange={event => {
              onSearchStringChange(event.target.value);
            }}
            placeholder="Search for enrollment code, name, email, address, date or application status"
          />
          <div className="search-icon">
            <SearchIcon width="25px" />
          </div>
        </div>
        <div className="icons">
          {showTechnicalSupport ? (
            <CSVLink
              data={TECHNICAL_REPORTS_ROWS}
              onClick={() => gaEventTracker("Download csv file")}
              headers={DOWNLOAD_TECHNICAL_REPORT_COLUMNS}
              filename={`TechnicalVerificationReport-${getCurrentDate()}.csv`}
            >
              <Tooltip title="Download csv file" arrow>
                <DownloadIcon />
              </Tooltip>
            </CSVLink>
          ) : (
            <CSVLink
              data={updatedData}
              onClick={() => gaEventTracker("Download csv file")}
              headers={DOWNLOAD_REPORT_COLUMNS}
              filename={`VerificationReport-${getCurrentDate()}.csv`}
            >
              <Tooltip title="Download csv file" arrow>
                <DownloadIcon />
              </Tooltip>
            </CSVLink>
          )}
          <FormControlLabel
            control={<Switch onChange={onToggleChange} />}
            label={
              showTechnicalSupport
                ? "Turn off Technical View"
                : "Turn on Technical View"
            }
          />
        </div>
      </div>
      <div className="line">
        <hr />
      </div>
    </>
  );
}
export default Tableheader;
