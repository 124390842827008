import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";
import useViewport from "../hooks/useViewPort";
import "./Stepper.scss";
import { useNavigate } from 'react-router-dom';
import { getUrlParams } from '../../utils/utils';
import { FormattedMessage } from 'react-intl';

function Stepper(props) {
  const { steps, activeStep, setActiveStep, setSteps } = props;
  const breakpoint = 768;

  const { width } = useViewport();
  const params = getUrlParams();
  const navigate = useNavigate();

  const handlePreviousBtnClick = () => {
    if (activeStep.id !== 1) {
      setActiveStep(steps[0]);
      navigate(
        `/editsearch?swa=${params.swa}&swa_xid=${params.swa_xid
        }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
      );
    }
  };

  const handleStepClick = (selectedStep) => {
    if (!selectedStep.disabled) {
      setSteps((prevSteps) => {
        let disableSteps = false;
        prevSteps.forEach((step) => {
          if (step.id === activeStep.id) {
            disableSteps = true;
          }
          if (disableSteps && step.id !== 1) {
            prevSteps[step.id - 1].disabled = true;
          }
        })
        return prevSteps;
      });
      setActiveStep(selectedStep);


      if (selectedStep.id === 1) {
        navigate(
          `/editsearch?swa=${params.swa}&swa_xid=${params.swa_xid
          }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
        );
      } else if (selectedStep.id === 2) {
        navigate(
          `/enrollment?swa=${params.swa}&swa_xid=${params.swa_xid
          }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
        );
      }
    }
  }
  return width < breakpoint ? (
    <MobileStepper
      activeStep={activeStep}
      handlePreviousBtnClick={handlePreviousBtnClick}
      steps={steps}
      handleStepClick={handleStepClick}
    />
  ) : (
    <DesktopStepper
      activeStep={activeStep}
      steps={steps}
      handleStepClick={handleStepClick}
    />
  );
}

function MobileStepper(props) {
  const {
    activeStep,
    handlePreviousBtnClick,
    handleStepClick,
    steps,
  } = props;

  return (
    <div className="mobile-stepper-container">
      <div className="mobile-stepper-navigations">
        {activeStep.id > 1 && (
          <button
            className="back-navigation-btn"
            onClick={() => handlePreviousBtnClick()}
          >
            <ArrowBackIcon />
            <span><FormattedMessage id="back_text" /></span>
          </button>
        )}

        <div className="dots-container">
          {steps.map((step, index) => {
            return (
              <div
                key={`${step.label}-${index}`}
                className={`step-dot ${step.id === steps[activeStep.id - 1].id
                  ? "dot-active"
                  : activeStep.id > index + 1
                    ? "dot-completed"
                    : ""
                  }`}
                onClick={() => handleStepClick(step)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DesktopStepper(props) {
  const {
    activeStep,
    steps,
    handleStepClick,
  } = props;

  const richText = {
    sup: chunks => <sup>{chunks}</sup>
  };

  return (
    <div className="desktop-stepper-container">
      <div className="steps">
        {steps.map((step, index) => {
          return (
            <div key={`${step.label}-${index}`} className={step.disabled ? "step-wrapper-disbaled" : "step-wrapper"} onClick={() => handleStepClick(step)}>
              <div
                className={`step-progress ${step.id === steps[activeStep.id - 1].id
                  ? "active"
                  : activeStep.id > index + 1
                    ? "completed"
                    : ""
                  }`}
              ></div>
              <p
                className={`step-label ${step.id === steps[activeStep.id - 1].id
                  ? "active-label"
                  : activeStep.id > index + 1
                    ? "completed-label"
                    : ""
                  }`}
              >
                <FormattedMessage id={step.label} values={{sup: data => <sup>{data}</sup>}}/>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Stepper;
