export default {
  //App level components texts
  header_text: "Verify your identity at a Post Office",
  header_text_sup: "TM",
  enrollment_component_heading: "Next steps for verifying your identity",
  reports_component_heading: "USPS in person verification report",
  updatedTechnicalHeader_text:"USPS in person verification technical report",
  of_text: "of",
  back_text: "BACK",

  //Enrollment component
  post_office_visit_text: "Post Office<sup>TM</sup> visit confirmation",
  enrollment_heading_text: "Visit the Post Office<sup>TM</sup> by",
  enrollment_description_para1:
    "  You will receive a copy of this via email. Bring this page or the email with you to the Post Office<sup>TM</sup>. You can also show a screenshot of the barcode.",
  enrollment_code_bold_text: "<b>Enrollment Code</b>",
  enrollment_description_para2_boldText:
    " Your barcode and enrollment will expire after 7 days.",
  enrollment_description_para2: "",
  print_this_pageBtn_text: "Print this page",
  enrollment_different_verification_method_link:
    "Choose a different verification method",
  enrollment_personal_information_text: "Personal information",
  enrollment_id_details_name_bold_text: "<b>Name:</b>",
  enrollment_id_details_email_bold_text: "<b>Email:</b>",
  enrollment_id_details_address_bold_text: "<b>Address:</b>",
  enrollment_id_details_editicon_text: "Edit",
  USPS_facilities_located_around: "Post Offices near:",

  //AddressSearch component
  personal_info_heading_text: "Personal information",
  heading_helper_text:
    "Enter your name and address exactly as they appear on your government-issued ID.",
  heading_helper_text_link: " ",
  form_required_info_text: "Mandatory fields below are marked with an asterisk",
  input_first_name: "First name",
  input_first_name_placeholder: "Enter first name",
  input_last_name: "Last name",
  input_last_name_placeholder: "Enter last name",
  input_email: "Email",
  input_email_placeholder: "Enter email",
  input_street_address_1: "Address",
  input_street_address_1_placeholder: "Enter street address",
  input_street_address_2: "Apartment, suite, unit, etc.",
  input_street_address_2_placeholder: "Enter street address",
  input_city_name_1: "City",
  input_city_name_placeholder: "Enter City",
  input_state_name: "State",
  input_state_name_placeholder: "State",
  Select_State_DropdownText: "Select State",
  input_zipcode: "Zip code",
  input_zipcode_placehoder: "Enter zip code",
  form_submit_button: "Submit",
  different_verification_method_link:
    "Choose a different verification method",

  //usps-description component
  required_Identity_documents: "Required Identity documents",
  acceptable_forms_of_ID: "Acceptable forms of ID:",
  State_drivers_license: "State driver's license",
  state_id_card: "State ID card",
  uniformed_id_cards:
    "U.S. Armed Forces or uniformed service ID card (requires secondary ID)",
  secondary_id: " U.S. Passport (requires secondary ID)",
  required_docs_heading: " We use the address on your ID for verification.",
  required_docs_para:
    "If you live somewhere else or your ID has no address listed, you will also need proof of address:",
  reqired_docs_list_lease: "Lease, mortgage, or deed of trust",
  reqired_docs_list_voter: "Voter registration card",
  reqired_docs_list_vehical: "Vehicle registration card",
  reqired_docs_list_home: "Home or vehicle insurance policy",
  postOffice_text: "The Post Office<sup>TM</sup>",
  postOffice_span: "will not accept:",
  list_social_security: "Social security cards",
  list_credit_card: "Credit card or utility bills",
  list_school_id: "State School ID",
  list_medical_marijuana: "Medical marijuana cards",
  list_concealed_licence: "Concealed carry licenses",
  list_expierd_forms: "Expired forms of ID",
  state_title: "",
  state_number: "",
  state_name: " ",
  state_timing_para: "Monday to Friday, 8:00 a.m. – 3:30 p.m Central Time",
  state_email: "",
  uicp_scroll_to_top_text: 'Return to Top',

  //ErrorPage component
  invalid_url_heading: "Invalid URL",
  invalid_url_para1:
    "It looks like the URL you entered is invalid or no longer exists. We cannot locate your ID verification number associated with your unemployment application.",
  invalid_url_para2:
    "The URL in your confirmation may have been entered incorrectly. <b>Please double-check the URL and try again.</b>",
  invalid_url_para3:"",
  hitawall_heading: "We seem to have a problem",
  hitawall_para1:
    "Verifying your identity with USPS is currently unavailable.",
  hitawall_para2:
    "We are working to resolve the issue as soon as possible and we apologize for any inconvenience this may have caused. Please try again later or choose a different verification method.",
  hitawall_para3: "Thank you for your patience and understanding. ",
  hitawall_para4:"",
  out_of_network_heading: "No participating Post Offices found",
  out_of_network_para1:
    "There are no participating Post Offices within 50 miles of your address to complete in-person ID verification with USPS at this time.",
  out_of_network_para2: "If you believe that the address provided below is incorrect, please update it to match the one listed on your government-issued identification in order to locate nearby Post Offices accurately.",
  out_of_network_para3: "If the information you have entered is correct and matches your government issued identification, you can choose a different verification method.",
  out_of_network_para4:"",
  
  // More info Component
  more_info_txt: " ",
  moreinfo_link: " ",

  // Farud Prevention Modal
  fraud_modal_header: "Welcome to the United States Department of Labor Identification Verification Page!",
  fraud_modal_para1: "You’re about to <b>confirm your identity for your unemployment claim</b> with Unemployment Insurance Office through USPS.",
  fraud_modal_para2: "<b>If you did not file for unemployment</b> with Unemployment Insurance Office, <b>please file a report</b> of potential unemployment identity fraud with Unemployment Insurance Office by following the steps at www.dol.gov/fraud.",
  fraud_modal_button: "I understand",

  // Address Modal
  address_modal_header: "Enter new address",
  address_modal_subheader: "Search for USPS facilities to verify your identity in a different location.",
  address_modal_zip: "Zip",
  address_modal_search_button: "Search",

  //FE Validation Errors
  fn_mainError: "Please enter your first name",
  fn_subError: "Allowed characters A-Z {br} You may also include hyphens, apostrophes and spaces",
  ln_mainError: "Please enter your last name",
  ln_subError: "Allowed characters A-Z {br} You may also include spaces, periods, hyphens, and apostrophes",
  email_mainError: "Your email must have a valid email format",
  email_subError: "Enter your email address in format: yourname@example.com",
  addr1_mainError: "Please enter your valid legal address",
  addr1_subError: "Allowed characters A-Z, 1-9 {br} You may also include spaces, hyphens, apostrophes, periods, forward slash “/” and number sign “#”",
  addr2_mainError: "Allowed characters A-Z, 1-9 {br} You may also include spaces, hyphens, apostrophes, periods, forward slash “/” and number sign “#”",
  city_mainError: "Please enter your city",
  city_subError: "Allowed characters A-Z {br} You may also include spaces, periods, hyphens, and apostrophes",
  zipcode_mainError: "Please enter your Zip Code",
  zipcode_subError: "<b>Please enter your Zip Code</b> {br} Up to 10-digit numeric including dashes",
  state_mainError: "Please select your state from the options below",

  //Header Component
  banner_header_text:"An official website of the United States government",
  banner_button_text:"Here's how you know",
  header_media_block: "Official websites use .gov",
  header_media_block_body: "Secure .gov websites use HTTPS",
  header_usdol_title: "U.S. Department of Labor",
  banner_lock_description_two: " means you've safely connected to the .gov website. Share sensitive information only on official, secure websites.",
  banner_lock_description_main: "A locked padlock",
  banner_lock_title: "Lock",
  banner_lock_description_one: "or",
  header_media_block_one: "lock",
  header_media_block_two: "A",
  header_media_block_two_inner: "A",
  header_media_block_three: " website belongs to an official government organization in the United States.",
  // Footer Component
  footer_nav_federal: "Federal Government",
  footer_nav_labor: "Labor Department",
  footer_nav_about: "About the Site",
  footer_connect_font: "Connect with DOL",
  footer_findit: "Site Map",
  footer_website_policies: "Important Website Notices",
  footer_privacy_notice: "Privacy & Security Statement",
  footer_dol_name_and_seal: "U.S. Department of Labor",
  footer_secondary_link_white_house: "White House",
  footer_secondary_link_corona_virus: "Coronavirus Resources",
  footer_secondary_link_storm_recovery: "Severe Storm and Flood Recovery Assistance",
  footer_secondary_link_disaster_recovery: "Disaster Recovery Assistance",
  footer_secondary_link_no_fear_act: "No Fear Act Data",
  footer_secondary_link_special_counsel: "U.S. Office of Special Counsel",
  footer_secondary_link_about_dol: "About DOL",
  footer_secondary_link_guidance_search: "Guidance Search",
  footer_secondary_link_inspector_general: "Office of Inspector General",
  footer_secondary_link_dol_newsletter: "Subscribe to the DOL Newsletter",
  footer_secondary_link_read_newsletter: "Read the DOL Newsletter",
  footer_secondary_link_accountability_status: "Emergency Accountability Status Link",
  footer_secondary_link_a_to_z_index: "A to Z Index",
  footer_secondary_link_information_act: "Freedom of Information Act",
  footer_secondary_link_disclaimers: "Disclaimers",
  footer_secondary_link_accessibility_statement: "Accessibility Statement",
};